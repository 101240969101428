// tslint:disable:no-submodule-imports

import * as React from "react";
import { ConfigProvider } from "antd";
import { useStoreState } from "../../../../store/hooks";

import nlNl from "antd/es/locale/nl_NL";
import enUs from "antd/es/locale/en_US";

export const BaseConfigProvider: React.FC = (props) => {
    const [locale, setLocale] = React.useState(nlNl);
    const language = useStoreState(
        (state) => state.auth.currentUser?.language || "NL"
    );

    React.useEffect(() => {
        if (language) {
            if (language === "EN") {
                setLocale(enUs);
            }

            setLocale(nlNl);
        }
    }, [language]);

    return <ConfigProvider locale={locale}>{props.children}</ConfigProvider>;
};
