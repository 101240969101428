import * as React from "react";
import { Order } from "../../models";
import { BaseTab } from "../../../base/components/BaseTab";
import { TableOutlined } from "@ant-design/icons";
import { t } from "../../../../translation";
import { useStoreActions } from "../../../../store/hooks";
import { Typography } from "antd";
import { EditOrderForm } from "../OrderForm/EditOrderForm";

interface IProps {
    order: Order;
}

export const OrderDetail: React.FC<IProps> = (props) => {
    const getItem = useStoreActions((actions) => actions.normalize.getItem);
    const reload = () => getItem({ subState: "orders", id: props.order.id });

    return (
        <>
            <Typography.Title level={3}>
                {t("orders.singular")}:{" "}
                {props.order.orderNr || t("forms.unknown")}
            </Typography.Title>
            <BaseTab
                tabs={[
                    {
                        title: (
                            <>
                                <TableOutlined />
                                {t("navigation.overview")}
                            </>
                        ),
                        content: (
                            <EditOrderForm
                                order={props.order}
                                onClose={() => reload()}
                            />
                        ),
                        key: "overview",
                    },
                ]}
            />
        </>
    );
};
