import { buttonsTranslationNl } from "./buttons";
import { usersTranslationNL } from "./users";
import { filtersTranslationNl } from "./filters";
import { navigationTranslationNl } from "./navigation";
import { groupsTranslationNL } from "./groups";
import { formsTranslationNl } from "./forms";
import { httpTranslationNl } from "./http";
import { homeTranslationNl } from "./home";
import { productsTranslationNl } from "./products";
import { productRatesTranslationNl } from "./productRates";
import { customersTranslationNl } from "./customers";
import { orderTranslationNl } from "./orders";
import { invoicesTranslationNl } from "./invoices";
import { tenantsTranslationNl } from "./tenants";

export const TranslationNl = {
    buttons: buttonsTranslationNl,
    filters: filtersTranslationNl,
    navigation: navigationTranslationNl,
    users: usersTranslationNL,
    groups: groupsTranslationNL,
    forms: formsTranslationNl,
    http: httpTranslationNl,
    home: homeTranslationNl,
    customers: customersTranslationNl,
    orders: orderTranslationNl,
    products: productsTranslationNl,
    productRates: productRatesTranslationNl,
    invoices: invoicesTranslationNl,
    tenants: tenantsTranslationNl,
};
