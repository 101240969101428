import { SubState, subStates } from "../../../../shared/normalizer";
import * as React from "react";
import { API_DATE_FORMAT } from "../../../../shared/functions";
import moment from "moment";
import { IInvoiceForm } from "../../../invoices/forms/invoiceInitialValues";

export * from "./BaseForm";
export * from "./FormDatePickerItem";
export * from "./FormSwitchItem";
export * from "./FormInputItem";
export * from "./FormTextAreaItem";
export * from "./FormInputNumberItem";
export * from "./FormSelectItem";
export * from "./FormSubmitButton";

/**
 * Returns dict with keys for each SubState with a function with accepts and returns the same input.
 */
const getDefaultCleaners = () => {
    const val: { [key: string]: (input: any) => any } = {};
    subStates.forEach((subState) => (val[subState] = (input) => input));
    return val as { [key in SubState]: (input: any) => any };
};

/**
 * Dict with all the SubStates with a cleaner function.
 */
export const FormCleaners: { [key in SubState]: (input: any) => any } = {
    ...getDefaultCleaners(),
    products: (input) => ({
        ...input,
        date: input.date
            ? moment(input.date).format(API_DATE_FORMAT)
            : undefined,
    }),
    orders: (input) => ({
        ...input,
        pickDate: moment(input.pickDate).format(API_DATE_FORMAT),
        deliveryDate: moment(input.deliveryDate).format(API_DATE_FORMAT),
    }),
    invoices: (input: IInvoiceForm) => ({
        ...input,
        date: moment(input.date).format(API_DATE_FORMAT),
        paymentDue: moment(input.paymentDue).format(API_DATE_FORMAT),
        invoiceRows: input.invoiceRows.map((invoiceRow) => ({
            ...invoiceRow,
            date: invoiceRow.date
                ? moment(invoiceRow.date).format(API_DATE_FORMAT)
                : null,
        })),
    }),
};

export const FormContext = React.createContext<{
    readOnly: boolean;
    fast?: boolean;
}>({
    readOnly: false,
    fast: undefined,
});
