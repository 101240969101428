import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { Group } from "../../../../modules/auth/models";

interface IGroupRow {
    key: number;
    name: string;
}

const getDataSource = (items: Group[]): IGroupRow[] =>
    items.map((item) => ({
        key: item.id,
        name: item.name,
    }));

const getColumns = (): AntColumnProps<IGroupRow>[] => [
    {
        key: "name",
        title: "name",
        dataIndex: "name",
    },
];

export const groupTableDefinition: ITableDefinition<IGroupRow, Group> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as IGroupRow,
    getColumns,
    getDataSource,
};
