import { BaseModel } from "../../normalized/models";
import { InvoiceRow } from "./invoiceRow";
import { Type } from "class-transformer";
import { Customer } from "../../customers/models";

export class Invoice extends BaseModel {
    id: string;

    invoiceNumber: string;

    date: string;
    paymentDue: string;

    reference: string;
    period: string;

    sentAt: string | null;

    @Type(() => Customer)
    customer: Customer;

    @Type(() => InvoiceRow)
    invoiceRows: InvoiceRow[];

    totalExcl(): number {
        return this.invoiceRows.reduce(
            (prev, cur) => prev + cur.totalExcl(),
            0
        );
    }

    totalIncl(): number {
        return this.invoiceRows.reduce(
            (prev, cur) => prev + cur.totalIncl(),
            0
        );
    }
}
