import * as React from "react";
import { Order } from "../../models";
import {
    BaseForm,
    FormDatePickerItem,
    FormInputNumberItem,
    FormSelectItem,
    FormTextAreaItem,
} from "../../../base/components/BaseForm";
import { t } from "../../../../translation";
import {
    getOrderInitialValues,
    getOrderValidationSchema,
    IOrderForm,
    orderToValues,
} from "../../forms/orderInitialValues";
import { useNormalizedState } from "../../../base/hooks";
import { Customer } from "../../../customers/models";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
import { EditOrderRowForm } from "./EditOrderRowForm";
// tslint:disable-next-line:no-submodule-imports
import { FormikHelpers } from "formik/dist/types";
import { FormSubmitButton } from "../../../base/components/BaseForm/FormSubmitButton/FormSubmitButton";
import moment from "moment";

interface IProps {
    order: Order | null;
    redirectToPdf?: boolean;
    onClose?: (helpers: FormikHelpers<IOrderForm>) => void;
}

export const EditOrderForm: React.FC<IProps> = (props) => {
    const { data: customers, isDone } = useNormalizedState<Customer>(
        "customers"
    );

    const originallyLoaded = React.useMemo(() => moment(), []);

    const checkDate = () => {
        // Check if it's a different date than the page was loaded originally, if so reload the page.
        if (!moment().isSame(originallyLoaded, "date")) {
            window.location.reload();
        }
    };

    if (!isDone) {
        return null;
    }

    return (
        <div onClick={() => checkDate()}>
            <BaseForm<IOrderForm>
                subState="orders"
                initialValues={
                    props.order
                        ? orderToValues(props.order)
                        : getOrderInitialValues()
                }
                validationSchema={getOrderValidationSchema()}
                onClose={(values, helpers) => {
                    if (props.redirectToPdf) {
                        window.open(`/api/orders/${values.id}/pdf/`, "_blank");
                    }

                    if (props.onClose) {
                        props.onClose(helpers);
                    }
                }}
                disableSubmitButton={true}
                onRender={(helpers) => (
                    <>
                        <FormSelectItem
                            label={t("customers.singular")}
                            withFilter={true}
                            name="customer"
                            options={customers
                                .filter((customer) => customer.isActive)
                                .map((customer) => ({
                                    label: customer.name,
                                    value: customer.id,
                                }))}
                            onChange={(customerId: string) => {
                                // If the customer is changed remove all the linked products.
                                if (helpers.values.customer !== customerId) {
                                    const newOrderRows = helpers.values.orderRows.map(
                                        (row) => {
                                            return {
                                                ...row,
                                                product: null,
                                            };
                                        }
                                    );
                                    helpers.setFieldValue(
                                        "orderRows",
                                        newOrderRows
                                    );
                                }
                            }}
                        />
                        <FormDatePickerItem
                            label={t("orders.fields.deliveryDate")}
                            name="deliveryDate"
                            picker="date"
                            allowClear={false}
                        />
                        <FormDatePickerItem
                            label={t("orders.fields.pickDate")}
                            name="pickDate"
                            picker="date"
                            allowClear={false}
                        />
                        <FormInputNumberItem
                            label={t("orders.fields.celNumber")}
                            name="celNumber"
                        />

                        <Card>
                            <EditOrderRowForm />
                        </Card>

                        <FormTextAreaItem
                            label={t("orders.fields.comment")}
                            name="comment"
                        />
                    </>
                )}
                extraActions={() => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: props.order
                                ? "space-between"
                                : "flex-end",
                            marginTop: "1em",
                        }}
                    >
                        {props.order && (
                            <Link
                                to={`/api/orders/${props.order.id}/pdf/`}
                                target="_blank"
                            >
                                <Button>{t("buttons.download")}</Button>
                            </Link>
                        )}
                        <FormSubmitButton />
                    </div>
                )}
            />
        </div>
    );
};
