export const navigationTranslationEn = {
    groups: "$t(groups.title)",
    logout: "Logout",
    notFound: {
        title: "Not found",
        subTitle: "Sorry, the page you visited does not exist.",
        backHome: "Back Home",
    },
    users: "$t(users.title)",
    customers: "$t(customers.title)",
    orders: "$t(orders.title)",
    invoices: "$t(invoices.title)",
    tenants: "$t(tenants.title)",
    admin: "Admin",
    edit: "Edit",
    create: "Create",
    detail: "Detail",
    overview: "Overview",
};
