import * as React from "react";
import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { User } from "../../../../modules/auth/models";
import { ColumnGenerator } from "../../ColumnGenerator";
import { FilterData } from "../../../normalizer";
import { Tag } from "antd";

interface IUserRow {
    key: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    lastLogin: string;
    groups: string[];
}

const getDataSource = (items: User[]): IUserRow[] => {
    return items.map((item) => ({
        key: item.id,
        username: item.username,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        lastLogin: item.lastLogin
            ? item.lastLogin.format("DD-MM-YYYY HH:mm")
            : "-",
        groups: item.groups.map((group) => group.name),
    }));
};

const getColumns = (
    filterData: FilterData | null,
    extra?: { [key: string]: any[] }
): AntColumnProps<IUserRow>[] => {
    const columnGenerator = new ColumnGenerator<IUserRow>(
        "users",
        filterData,
        []
    );

    columnGenerator.generateColumn("username", {
        enableFilter: true,
        enableSorter: true,
    });
    columnGenerator.generateColumn("firstName", {
        enableFilter: true,
    });
    columnGenerator.generateColumn("lastName", {
        enableFilter: true,
    });
    columnGenerator.generateColumn("email", {
        enableFilter: true,
    });
    columnGenerator.generateColumn("lastLogin", {
        enableSorter: true,
    });

    if (extra && "groups" in extra) {
        columnGenerator.generateColumn("groups", {
            items: extra.groups.map((group) => ({
                text: group.name,
                value: group.id,
            })),
            enableFilter: true,
            render: (groupNames: string[]) => (
                <>
                    {groupNames.map((groupName) => (
                        <Tag key={groupName}>{groupName}</Tag>
                    ))}
                </>
            ),
        });
    }

    return columnGenerator.columns;
};

export const userTableDefinition: ITableDefinition<IUserRow, User> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as IUserRow,
    getColumns,
    getDataSource,
};
