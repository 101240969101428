import * as React from "react";
import { FilterData, SubState } from "../../../../shared/normalizer";
import { Col, DatePicker, Input, Row } from "antd";
import { t } from "../../../../translation";
import { useDebouncedCallback } from "use-debounce";
import { DATE_FORMAT } from "../../../../shared/functions";
import { ID } from "../../../../shared/interfaces";
import moment, { Moment } from "moment";

const globalSearchKey = "search";
const globalDateAfterKey = "dateAfter";
const globalDateBeforeKey = "dateBefore";

interface IProps {
    subState: SubState;
    onFilterChange: (filters: Record<string, ID[] | null>) => void;
    filterData: FilterData;
    enableSearch?: boolean;
}

export const BaseOverviewFilter: React.FC<IProps> = (props) => {
    const initialSearchValue = props.filterData[globalSearchKey];
    const dateAfterValue = props.filterData[globalDateAfterKey];
    const dateBeforeValue = props.filterData[globalDateBeforeKey];

    const dateValue: [Moment, Moment] | null =
        dateAfterValue && dateBeforeValue
            ? [
                  moment(dateAfterValue[0], DATE_FORMAT),
                  moment(dateBeforeValue[0], DATE_FORMAT),
              ]
            : null;

    const debouncedSearch = useDebouncedCallback(
        (value: string) =>
            props.onFilterChange({
                [globalSearchKey]: [value],
            }),
        1000
    );

    return (
        <Row align="middle">
            {props.enableSearch && (
                <Col span={6}>
                    <Input
                        placeholder={t("search")}
                        defaultValue={initialSearchValue}
                        onChange={(e) => debouncedSearch(e.target.value)}
                    />
                </Col>
            )}

            <Col span={10} offset={1}>
                <DatePicker.RangePicker
                    format={DATE_FORMAT}
                    value={dateValue}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                        if (!value || !value[0] || !value[1]) {
                            props.onFilterChange({
                                [globalDateAfterKey]: null,
                                [globalDateBeforeKey]: null,
                            });
                        } else {
                            props.onFilterChange({
                                [globalDateAfterKey]: [
                                    value[0].format(DATE_FORMAT),
                                ],
                                [globalDateBeforeKey]: [
                                    value[1].format(DATE_FORMAT),
                                ],
                            });
                        }
                    }}
                />
            </Col>
        </Row>
    );
};
