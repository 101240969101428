import * as React from "react";
import { Invoice } from "../models";
import { BaseEditScreen } from "../../base/components/BaseEditScreen";
import { EditInvoiceForm } from "../components/EditInvoiceForm";
import { useHistory } from "react-router-dom";
import { getAuthenticatedPath } from "../../../shared/routes";

interface IProps {
    match: { params: { id: string | undefined } };
}

export const InvoiceEditScreen: React.FC<IProps> = (props) => {
    const history = useHistory();

    return (
        <BaseEditScreen<Invoice>
            match={props.match}
            subState="invoices"
            editComponent={(invoice) => (
                <EditInvoiceForm
                    invoice={invoice}
                    onClose={(values) =>
                        history.push(
                            getAuthenticatedPath("invoices-detail", {
                                id: values.id!,
                            })
                        )
                    }
                />
            )}
        />
    );
};
