import { AntColumnProps } from "../types";
import { groupTableDefinition, userTableDefinition } from "./subStates";
import { FilterData } from "../../normalizer";
import { customerTableDefinition } from "./subStates/customers";
import { productTableDefinition } from "./subStates/products";
import { productRateTableDefinition } from "./subStates/productRates";
import { orderTableDefinition } from "./subStates/orders";
import { invoiceTableDefinition } from "./subStates/invoices";

export interface ITableDefinition<Row, Model> {
    row: Row;
    getColumns: (
        filterData: FilterData | null,
        extra?: { [key: string]: any[] }
    ) => AntColumnProps<Row>[];
    getDataSource: (items: Model[]) => Row[];
}

export const TableDefinitions = {
    users: userTableDefinition,
    groups: groupTableDefinition,
    customers: customerTableDefinition,
    products: productTableDefinition,
    productRates: productRateTableDefinition,
    orders: orderTableDefinition,
    invoices: invoiceTableDefinition,
};
