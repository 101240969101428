import * as React from "react";
import { Button } from "antd";

import { useHistory } from "react-router-dom";
import { t } from "../../../../translation";
import { Formik } from "formik";
// tslint:disable-next-line:no-submodule-imports
import { FormikHelpers } from "formik/dist/types";
import { getUnAuthenticatedPath } from "../../../../shared/routes";
import { Form, Input } from "formik-antd";
import { css, StyleSheet } from "aphrodite";
import { FormSubmitButton } from "../../../base/components/BaseForm/FormSubmitButton/FormSubmitButton";

interface IProps {
    onSubmit: (
        values: InitialDataConfirmPasswordResetForm,
        helper: FormikHelpers<InitialDataConfirmPasswordResetForm>
    ) => void;
    isLoading: boolean;
}

const initialData = {
    password: "",
    passwordConfirm: "",
};

const styles = StyleSheet.create({
    button: {
        width: "100%",
    },
    marginTop: {
        marginTop: "1em",
    },
});

export type InitialDataConfirmPasswordResetForm = typeof initialData;

export const ConfirmPasswordResetForm: React.FC<IProps> = (props: IProps) => {
    const history = useHistory();

    return (
        <Formik<InitialDataConfirmPasswordResetForm>
            initialValues={initialData}
            onSubmit={(values, helper) => props.onSubmit(values, helper)}
        >
            <Form>
                <Form.Item name="password">
                    <Input
                        name="password"
                        type="password"
                        placeholder={t("users.fields.password").toLowerCase()}
                    />
                </Form.Item>
                <Form.Item name="passwordConfirm">
                    <Input
                        name="passwordConfirm"
                        type="password"
                        placeholder={t("users.fields.password").toLowerCase()}
                    />
                </Form.Item>
                <FormSubmitButton className={css(styles.button)}>
                    {t("buttons.edit")}
                </FormSubmitButton>
                <Button
                    type="default"
                    loading={props.isLoading}
                    className={css([styles.button, styles.marginTop])}
                    onClick={() =>
                        history.push(getUnAuthenticatedPath("login"))
                    }
                >
                    {t("users.auth.title")}
                </Button>
            </Form>
        </Formik>
    );
};
