import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { ColumnGenerator } from "../../ColumnGenerator";
import { FilterData } from "../../../normalizer";
import { Product } from "../../../../modules/customers/models";
import { formatCurrency } from "../../../functions/currency";

interface IProductRow {
    key: string;
    name: string;
    weight: number;
    price: string;
}

const getDataSource = (items: Product[]): IProductRow[] => {
    return items.map((item) => ({
        key: item.id,
        name: item.name,
        weight: item.weight,
        price: formatCurrency(item.price),
    }));
};

const getColumns = (
    filterData: FilterData | null
): AntColumnProps<IProductRow>[] => {
    const columnGenerator = new ColumnGenerator<IProductRow>(
        "products",
        filterData,
        []
    );

    columnGenerator.generateColumn("name");

    columnGenerator.generateColumn("weight");

    columnGenerator.generateColumn("price");

    return columnGenerator.columns;
};

export const productTableDefinition: ITableDefinition<IProductRow, Product> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as IProductRow,
    getColumns,
    getDataSource,
};
