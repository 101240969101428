import * as React from "react";
import { Customer } from "../../models";
import {
    BaseForm,
    FormInputItem,
    FormSelectItem,
    FormSwitchItem,
    FormTextAreaItem,
} from "../../../base/components/BaseForm";
import { t } from "../../../../translation";
import {
    customerToValues,
    getCustomerInitialValues,
    getCustomerValidationSchema,
    ICustomerForm,
} from "../../forms/customerInitialValues";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { Select } from "formik-antd";

interface IProps {
    customer: Customer | null;
    onClose?: () => void;
}

export const EditCustomerForm: React.FC<IProps> = (props) => {
    const getCountries = useStoreActions(
        (actions) => actions.customers.getCountries
    );
    const countries = useStoreState((state) => state.customers.countries);

    React.useEffect(() => {
        getCountries();
    }, []);

    return (
        <BaseForm<ICustomerForm>
            subState="customers"
            initialValues={
                props.customer
                    ? customerToValues(props.customer)
                    : getCustomerInitialValues()
            }
            validationSchema={getCustomerValidationSchema()}
            onClose={() => (props.onClose ? props.onClose() : null)}
        >
            <FormInputItem
                extraItemProps={{ required: true }}
                label={t("customers.fields.name")}
                name="name"
            />
            <FormInputItem
                extraItemProps={{ required: true }}
                label={t("customers.fields.address")}
                name="address"
            />
            <FormSelectItem
                extraItemProps={{ required: true }}
                label={t("customers.fields.country")}
                name="country"
                withFilter={true}
            >
                {countries.map((country) => (
                    <Select.Option key={country.code} value={country.code}>
                        {country.name}
                    </Select.Option>
                ))}
            </FormSelectItem>
            <FormInputItem
                extraItemProps={{ required: true }}
                label={t("customers.fields.invoiceEmail")}
                name="invoiceEmail"
            />
            <FormSwitchItem
                label={t("customers.fields.invoiceSubTotals")}
                name="invoiceSubTotals"
            />
            <FormInputItem
                label={t("customers.fields.bioNumber")}
                name="bioNumber"
            />
            <FormInputItem
                label={t("customers.fields.vatNumber")}
                name="vatNumber"
            />
            <FormInputItem
                label={t("customers.fields.cocNumber")}
                name="cocNumber"
            />
            <FormInputItem
                label={t("customers.fields.contactPersonName")}
                name="contactPersonName"
            />
            <FormInputItem
                label={t("customers.fields.contactPersonEmail")}
                name="contactPersonEmail"
            />
            <FormTextAreaItem
                label={t("customers.fields.noteOnPdf")}
                name="noteOnPdf"
            />
            <FormSwitchItem
                label={t("customers.fields.hideOnDashboard")}
                name="hideOnDashboard"
            />
            <FormSwitchItem
                label={t("customers.fields.isActive")}
                name="isActive"
            />
        </BaseForm>
    );
};
