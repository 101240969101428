import * as React from "react";
import { Button, Popconfirm, Table } from "antd";
import { FilterData, SubState } from "../../../../shared/normalizer";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { TableDefinitions } from "../../../../shared/table/definitions";
import { getActiveItems } from "../../../normalized/selectors/getItemsSelector";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons/lib";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import {
    DEFAULT_PAGE_SIZE,
    HIDE_ON_SINGLE_PAGE,
    ORDERING_KEY,
    PAGE_KEY,
    PAGE_SIZE,
} from "../../../../shared/table/constants";
import { AntColumnProps } from "../../../../shared/table/types";
import { BasePermissionWrapper } from "../BasePermissionWrapper";
import {
    prepareFiltersForUrl,
    wrapValueWithArray,
} from "../../../../shared/functions/objectHelpers";
import { ID } from "../../../../shared/interfaces";
import { BaseActionBar } from "../BaseActionBar";
import { Title } from "./Title";
import { t } from "../../../../translation";
import { css, StyleSheet } from "aphrodite";
import { IPopupProps, Popup } from "./Popup";
import { useFocusEffect } from "../../hooks";

interface IProps<T> {
    /**
     * The subState for which the table should be generated.
     */
    subState: SubState;
    /**
     * Boolean if the filters should be saved in the url.
     */
    filtersInUrl?: boolean;
    /**
     * Boolean if the websockets should be enabled for this state.
     */
    enableWebSockets?: boolean;
    /**
     * Boolean which determines if the delete option should be removed.
     */
    disableDelete?: boolean;
    /**
     * Boolean which determines if the selection should be removed.
     */
    disableSelection?: boolean;
    /**
     * Array which contains extra columns for the table.
     */
    extraColumns?: AntColumnProps<any>[];
    /**
     * String which will override the default title based on the subState.
     */
    title?: string;
    /**
     * Dict which contains data which can be used to fill the table or the filters for the table.
     */
    extraColumnData?: { [key: string]: any[] };
    /**
     * Function which will override the default reroute to /subState/new on button create click.
     */
    onCreate?: () => void;
    /**
     * Function which will set the onDoubleClick event when a row is double clicked.
     */
    onItemDoubleClick?: (record: T) => void;
    /**
     * React node which will be rendered in PopUp when context menu is opened.
     */
    contextMenu?: (record: T) => React.ReactNode;
    /**
     * Override the data source.
     */
    dataSource?: T[];
    /**
     * CallBack which is ran after deletion
     */
    afterDelete?: () => void;
    /**
     * Callback when editButton is selected.
     */
    onEdit?: (record: T) => void;
    /**
     * Override height.
     */
    height?: number | string;
    /**
     * Show the action bar.
     */
    showActionBar?: boolean;
    /**
     * Always fetch on mount.
     */
    forceFetchOnMount?: boolean;
    /**
     * Always fetch on focus.
     */
    forceFetchOnFocus?: boolean;
    /**
     * Disabled sorters/filters.
     */
    disableFilters?: boolean;
    /**
     * Extra filters which will be applied when fetching the data.
     */
    extraFilters?: { [key: string]: string };
    /**
     * If globalFilters should be enabled.
     */
    globalFilters?: boolean;
}

const styles = StyleSheet.create({
    row: {
        cursor: "pointer",
    },
});
export const BaseTable = <T extends { id: ID }>(
    props: React.PropsWithChildren<IProps<T>>
) => {
    const history = useHistory();
    const location = useLocation();
    const meta = useStoreState((state) => state.normalize.meta[props.subState]);

    if (!(props.subState in TableDefinitions)) {
        throw Error("Can't render without table definition");
    }
    // @ts-ignore since its possible it doesn't exist.
    const tableDefinition = TableDefinitions[props.subState];
    const itemsDone = useStoreState(
        (state) => state.normalize.meta[props.subState].get.done
    );
    const retrievedItems: any[] = useStoreState((state) =>
        getActiveItems(state, props.subState)
    );

    const items = props.dataSource || retrievedItems;

    const currentUser = useStoreState((state) => state.auth.currentUser);

    // Actions
    const {
        deleteItem,
        setWebSocket,
        getItems,
        selectAllItems,
        selectItem,
        setFilters,
    } = useStoreActions((actions) => actions.normalize);

    const [popup, setPopup] = React.useState<IPopupProps>({
        record: null,
        visible: false,
        x: 0,
        y: 0,
        content: null,
    });

    React.useEffect(() => {
        // Check if there are query params if so fetch the page with the given filters
        if (props.filtersInUrl && location.search) {
            const params = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            });
            const filterData = wrapValueWithArray(params);
            if (params.ordering) {
                filterData[ORDERING_KEY] = params.ordering;
            }
            setFilterData(filterData);
        } else if (!itemsDone || props.forceFetchOnMount) {
            reload();
        }
    }, []);

    useFocusEffect(() => {
        if (props.forceFetchOnFocus) {
            reload();
        }
    });

    // Enable or disable websockets
    React.useEffect(() => {
        setWebSocket({
            subState: props.subState,
            enable: !!props.enableWebSockets,
        });

        return () => {
            setWebSocket({
                subState: props.subState,
                enable: false,
            });
        };
    }, [props.enableWebSockets]);

    // Function to reload the items with the filters within the state
    const reload = () => {
        if (!currentUser?.hasPermission(props.subState, "view")) return;
        if (!!props.dataSource) return;
        getItems({
            subState: props.subState,
            extraFilters: props.extraFilters,
        });
    };

    /**
     * Adds/overrides/deletes the filters from the current filters.
     * @param filters filters from the table onChange function.
     */
    const setNewFilters = (filters: Record<string, ID[] | null>) => {
        const newFilters: FilterData = {
            ...meta.filters,
        };

        let ordering: ID[] | null | ID = filters[ORDERING_KEY];

        if (Array.isArray(ordering)) {
            ordering = ordering[0];
        }

        // Convert all values to strings and delete keys without an array with a minimal length of 1.
        Object.keys(filters).forEach((key: string) => {
            const filter = filters[key];
            if (filter && Array.isArray(filter) && filter.length > 0) {
                newFilters[key] = filter.map((fil) => fil.toString());
            } else {
                delete newFilters[key];
            }
        });

        if (ordering) {
            newFilters[ORDERING_KEY] = ordering.toString();
        }
        setFilterData(newFilters);
    };

    /**
     * Override the complete filters object in the state.
     * @param filters: new FilterData which should override the current filter state.
     */
    const setFilterData = (filters: FilterData) => {
        // Call the filter action
        setFilters({ subState: props.subState, filters });

        // Update the filters in the url if enabled
        if (props.filtersInUrl) {
            history.push({
                search: prepareFiltersForUrl(filters),
            });
        }

        // Reload the data based on the new filters
        reload();
    };

    /**
     * Find item by key.
     * @param key: string for which item should be found by its id.
     */
    const getItemByKey = (key: ID): T | null => {
        return items.find((item) => item.id === key) || null;
    };

    /**
     * Handler fucntion for onRow right click.
     */
    const onContextMenu = (event: React.MouseEvent, record: any) => {
        if (!props.contextMenu) {
            return;
        }

        event.preventDefault();

        document.addEventListener(`click`, function onClickOutside() {
            setPopup({ ...popup, visible: false });
            document.removeEventListener(`click`, onClickOutside);
        });

        const item = getItemByKey(record.key);
        if (item) {
            setPopup({
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
                content: props.contextMenu(item),
            });
        }
    };

    // Prevent the table from loading if no tableDefinition was found.
    if (!tableDefinition) {
        throw Error(`No table was found for subState: ${props.subState}`);
    }

    let columns: AntColumnProps<any>[] = [];

    if (
        props.onEdit !== undefined &&
        currentUser?.hasPermission(props.subState, "change")
    ) {
        columns.push({
            align: "left",
            width: "50px",
            render: (record: typeof tableDefinition.row) => (
                <Button
                    shape="circle"
                    type="dashed"
                    icon={<EditOutlined />}
                    onClick={() => {
                        const item = getItemByKey(record.key);
                        if (item && props.onEdit) {
                            props.onEdit(item);
                        }
                    }}
                    data-cy="button-edit"
                />
            ),
        });
    }

    columns.push(
        ...tableDefinition.getColumns(meta.filters, props.extraColumnData)
    );

    if (props.extraColumns) {
        columns.push(...props.extraColumns);
    }

    // Add delete column if not disabled and currentUser has permission
    if (
        currentUser?.hasPermission(props.subState, "delete") &&
        (props.disableDelete === undefined || !props.disableDelete)
    ) {
        columns.push({
            align: "right",
            width: "50px",
            render: (record: typeof tableDefinition.row) => (
                <>
                    <Popconfirm
                        title="Are you sure you want to delete this item?"
                        onConfirm={() => {
                            deleteItem({
                                subState: props.subState,
                                id: record.key,
                            }).then(
                                () => props.afterDelete && props.afterDelete()
                            );
                        }}
                        okText={t("forms.yes")}
                        cancelText={t("forms.no")}
                    >
                        <Button
                            type="danger"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            data-cy="button-delete"
                        />
                    </Popconfirm>
                </>
            ),
        });
    }

    // if the filters are disabled make sure to not render all the extra props.
    if (props.disableFilters) {
        columns = columns.map((column) => {
            return {
                dataIndex: column.dataIndex,
                key: column.key,
                title: column.title,
                width: column.width,
                render: column.render,
                ellipsis: column.ellipsis,
            };
        });
    }

    return (
        <BasePermissionWrapper
            permission="view"
            subState={props.subState}
            showMessage={true}
        >
            <Table<any>
                columns={columns}
                dataSource={tableDefinition.getDataSource(items)}
                loading={meta.get.loading}
                size="small"
                bordered={true}
                title={() => (
                    <Title
                        subState={props.subState}
                        reload={reload}
                        hideReload={!!props.dataSource}
                        title={props.title}
                        clearFilters={() =>
                            setFilterData({
                                page: ["1"],
                            })
                        }
                        setNewFilters={setNewFilters}
                        globalFilters={props.globalFilters}
                    />
                )}
                pagination={{
                    total: meta.totalRecords,
                    current: meta.currentPage,
                    pageSize: PAGE_SIZE,
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    hideOnSinglePage: HIDE_ON_SINGLE_PAGE,
                }}
                rowSelection={
                    props.disableSelection
                        ? undefined
                        : {
                              selectedRowKeys: meta.selectedIds,
                              onSelect: (item) =>
                                  selectItem({
                                      subState: props.subState,
                                      id: item.key,
                                  }),
                              onSelectAll: (selected) =>
                                  selectAllItems({
                                      subState: props.subState,
                                      selected,
                                  }),
                          }
                }
                rowClassName={
                    props.onItemDoubleClick ? css(styles.row) : undefined
                }
                onRow={(record, _) => ({
                    onDoubleClick: () => {
                        if (props.onItemDoubleClick) {
                            const item = getItemByKey(record.key);
                            if (item) {
                                props.onItemDoubleClick(item);
                            }
                            return;
                        }
                    },
                    onContextMenu: (event) => onContextMenu(event, record),
                })}
                scroll={{
                    y: props.height || "calc(100vh - 385px - 3em)",
                    scrollToFirstRowOnChange: true,
                }}
                onChange={(pagination, filters, sorter) => {
                    const newSorter = Array.isArray(sorter)
                        ? sorter[0]
                        : sorter;

                    // // Parse ordering.
                    const ordering = !!newSorter.order
                        ? `${newSorter.order === "descend" ? "-" : ""}${
                              newSorter.columnKey
                          }`
                        : null;

                    filters[PAGE_KEY] = [(pagination.current || 1).toString()];
                    filters[ORDERING_KEY] = ordering ? [ordering] : null;
                    setNewFilters(filters);
                }}
            />
            {popup.visible && <Popup {...popup} />}

            {!!props.showActionBar && (
                <BaseActionBar>
                    <BasePermissionWrapper
                        subState={props.subState}
                        permission="add"
                    >
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                if (props.onCreate) {
                                    props.onCreate();
                                    return;
                                }
                                history.push(`/${props.subState}/new`);
                            }}
                            data-cy="button-create"
                        >
                            {t("buttons.create")}
                        </Button>
                    </BasePermissionWrapper>
                    {props.children}
                </BaseActionBar>
            )}
        </BasePermissionWrapper>
    );
};
