import * as React from "react";
import { Modal } from "antd";
import { EditUserForm } from "../components/EditUserForm";
import { User } from "../../auth/models";
import { BasePermissionWrapper } from "../../base/components/BasePermissionWrapper";
import { t } from "../../../translation";

interface IProps {
    user: User | null;
    visible: boolean;
    onClose: () => void;
}

export const EditUserModal: React.FC<IProps> = (props) => {
    return (
        <Modal
            title={
                props.user
                    ? t("users.form.edit", {
                          username: props.user.username,
                      })
                    : t("users.form.add")
            }
            visible={props.visible}
            centered={true}
            footer={null}
            onCancel={() => props.onClose()}
            destroyOnClose={true}
        >
            <BasePermissionWrapper
                subState="users"
                permission="change"
                showMessage={true}
            >
                <EditUserForm user={props.user} onClose={props.onClose} />
            </BasePermissionWrapper>
        </Modal>
    );
};
