import * as React from "react";
import { User } from "../../../auth/models";
import { t } from "../../../../translation";
import { BaseTable } from "../../../base/components";
import { EditUserModal } from "../../screens";

export const UserTable: React.FC = () => {
    const [editUserModal, setEditUserModal] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

    return (
        <>
            <EditUserModal
                user={selectedUser}
                visible={editUserModal}
                onClose={() => setEditUserModal(false)}
            />
            <BaseTable<User>
                subState="users"
                title={t("users.title")}
                enableWebSockets={true}
                onEdit={(user) => {
                    setSelectedUser(user);
                    setEditUserModal(true);
                }}
                showActionBar={true}
            />
        </>
    );
};
