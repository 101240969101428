import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { ColumnGenerator } from "../../ColumnGenerator";
import { FilterData } from "../../../normalizer";
import { Invoice } from "../../../../modules/invoices/models";
import { formatCurrency } from "../../../functions/currency";
import { API_DATE_FORMAT, DATE_FORMAT } from "../../../functions";
import moment from "moment";

interface IInvoiceRow {
    key: string;
    invoiceNumber: string;
    date: string;
    customer: string;
    totalExcl: string;
    totalIncl: string;
    isSent: boolean;
}

const getDataSource = (items: Invoice[]): IInvoiceRow[] => {
    return items.map((item) => ({
        key: item.id,
        invoiceNumber: item.invoiceNumber,
        date: moment(item.date, API_DATE_FORMAT).format(DATE_FORMAT),
        customer: item.customer.name,
        totalExcl: formatCurrency(item.totalExcl()),
        totalIncl: formatCurrency(item.totalIncl()),
        isSent: !!item.sentAt,
    }));
};

const getColumns = (
    filterData: FilterData | null
): AntColumnProps<IInvoiceRow>[] => {
    const columnGenerator = new ColumnGenerator<IInvoiceRow>(
        "invoices",
        filterData,
        []
    );

    columnGenerator.generateColumn("invoiceNumber");
    columnGenerator.generateColumn("date");
    columnGenerator.generateColumn("customer");
    columnGenerator.generateColumn("totalExcl");
    columnGenerator.generateColumn("totalIncl");
    columnGenerator.generateColumn("isSent", { type: "bool" });

    return columnGenerator.columns;
};

export const invoiceTableDefinition: ITableDefinition<IInvoiceRow, Invoice> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as IInvoiceRow,
    getColumns,
    getDataSource,
};
