import * as React from "react";
import { useStoreActions } from "../../../store/hooks";
import { useHistory } from "react-router-dom";
import { getAuthenticatedPath } from "../../../shared/routes";
import { BaseEditScreen } from "../../base/components/BaseEditScreen";
import { Order } from "../models";
import { EditOrderForm } from "../components/OrderForm/EditOrderForm";

interface IProps {
    match: { params: { id: number | undefined } };
}

export const OrderEditScreen: React.FC<IProps> = (props) => {
    const history = useHistory();

    const { getItems } = useStoreActions((actions) => actions.normalize);

    return (
        <BaseEditScreen<Order>
            match={props.match}
            subState="orders"
            editComponent={(order) => (
                <>
                    <EditOrderForm
                        order={order}
                        onClose={() => {
                            if (!order) {
                                getItems({ subState: "orders" });
                            }
                            history.push(getAuthenticatedPath("orders"));
                        }}
                    />
                </>
            )}
        />
    );
};
