import { Button, Checkbox, DatePicker, Input, Menu } from "antd";
import * as React from "react";
// tslint:disable-next-line:no-submodule-imports
import { FilterDropdownProps } from "antd/es/table/interface";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons/lib";
// tslint:disable-next-line:no-submodule-imports
import { CheckboxChangeEvent } from "antd/es/checkbox";

import "./columns.less";
import { t } from "../../../translation";
import { css, StyleSheet } from "aphrodite";
import { DATE_FORMAT, moment } from "../../functions";

export interface IColumn {
    text: string;
    value: string;
}

export type InputType = "text" | "number" | "date" | "bool";

interface IOptions {
    type?: InputType;
    items?: IColumn[];
    initialValues?: string[];
}

let searchInput: Input | null = null;

const styles = StyleSheet.create({
    root: {
        padding: "8px",
    },
    half: {
        width: "50%",
    },
    right: {
        float: "right",
    },
    iconButton: { width: "100%", marginTop: "0.5em" },
    inputFilter: {
        width: "100%",
        display: "block",
        margin: "auto auto 8px auto",
    },
    menuFilter: {
        maxHeight: "25em",
        overflowY: "auto",
        width: "20em",
        marginBottom: "1em",
    },
});

const CustomFilterDropdown: React.FC<FilterDropdownProps & {
    type: InputType;
    allItems?: IColumn[];
    initialValues?: string[];
}> = (props) => {
    const [items, setItems] = React.useState<IColumn[]>(props.allItems || []);

    React.useEffect(() => {
        if (props.allItems) {
            setItems(props.allItems);
        }
    }, [props.allItems]);

    const getDefaultValue = (): string => {
        const initialValue =
            props.initialValues && props.initialValues.length
                ? props.initialValues[0]
                : null;
        if (props.allItems && initialValue) {
            const item = props.allItems.find(
                (fItem) => fItem.value === initialValue
            );
            return item ? item.text : "";
        }
        return initialValue || "";
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = e.target.value;
        if (props.allItems) {
            setItems(
                props.allItems.filter(
                    (item) =>
                        item.text
                            .toLowerCase()
                            .indexOf(searchString.toLowerCase()) > -1
                )
            );
        } else if (props.setSelectedKeys) {
            props.setSelectedKeys(e.target.value ? [e.target.value] : []);
        }
    };

    const onCheckboxChange = (e: CheckboxChangeEvent, item: IColumn) => {
        if (props.setSelectedKeys) {
            if (e.target.checked) {
                props.setSelectedKeys(
                    props.selectedKeys
                        ? [
                              ...props.selectedKeys.map(
                                  (selectedKey: React.Key) =>
                                      selectedKey.toString()
                              ),
                              item.value.toString(),
                          ]
                        : [item.value.toString()]
                );
            } else {
                props.setSelectedKeys(
                    props.selectedKeys
                        ? props.selectedKeys
                              .filter(
                                  (selectedKey: React.Key) =>
                                      selectedKey.toString() !==
                                      item.value.toString()
                              )
                              .map((selectedKey) => selectedKey.toString())
                        : []
                );
            }
        }
    };

    return (
        <div className={css(styles.root)}>
            <Input
                ref={(node) => {
                    searchInput = node;
                }}
                type={props.type}
                placeholder={`${t("filters.search")}...`}
                defaultValue={getDefaultValue()}
                onChange={(e) => onInputChange(e)}
                onPressEnter={() => {
                    if (props.confirm) {
                        props.confirm();
                    }
                }}
                className={css(styles.inputFilter)}
            />
            {props.allItems && (
                <Menu className={css(styles.menuFilter)}>
                    <div style={{ margin: "8px 0" }}>
                        <Button
                            className={css(styles.half)}
                            onClick={() => {
                                if (props.setSelectedKeys && props.allItems) {
                                    props.setSelectedKeys(
                                        props.allItems.map((item) =>
                                            item.value.toString()
                                        )
                                    );
                                }
                            }}
                        >
                            {t("filters.selectAll")}
                        </Button>
                        <Button
                            className={css([styles.half, styles.right])}
                            onClick={() => {
                                if (props.clearFilters) {
                                    props.clearFilters();
                                }
                            }}
                        >
                            {t("filters.selectNone")}
                        </Button>
                    </div>
                    {items.map((item) => (
                        <Menu.Item key={item.value} style={{ margin: 0 }}>
                            <Checkbox
                                checked={
                                    props.selectedKeys &&
                                    props.selectedKeys.includes(
                                        item.value.toString()
                                    )
                                }
                                onChange={(e) => onCheckboxChange(e, item)}
                            />
                            <span>{item.text}</span>
                        </Menu.Item>
                    ))}
                </Menu>
            )}
            {!props.allItems && (
                <Button
                    className={css(styles.iconButton)}
                    onClick={() => {
                        if (props.clearFilters) {
                            props.clearFilters();
                        }
                    }}
                >
                    {t("filters.reset")}
                    <DeleteOutlined />
                </Button>
            )}
            <Button
                type="primary"
                className={css(styles.iconButton)}
                onClick={() => {
                    if (props.confirm) {
                        props.confirm();
                    }
                }}
            >
                {t("filters.search")}
                <SearchOutlined />
            </Button>
        </div>
    );
};

const CustomDateFilterDropdown: React.FC<FilterDropdownProps & {
    initialValue?: string;
}> = (props) => {
    return (
        <div style={{ padding: 8, width: "20em" }}>
            <DatePicker
                style={{ width: "100%" }}
                format={DATE_FORMAT}
                defaultValue={
                    props.initialValue
                        ? moment(props.initialValue, DATE_FORMAT)
                        : undefined
                }
                onChange={(_, dateString) => {
                    if (props.setSelectedKeys) {
                        props.setSelectedKeys(dateString ? [dateString] : []);
                    }
                }}
                allowClear={false}
            />
            <Button
                className={css(styles.iconButton)}
                onClick={() => {
                    if (props.clearFilters) {
                        props.clearFilters();
                    }
                }}
            >
                {t("filters.reset")}
                <DeleteOutlined />
            </Button>
            <Button
                type="primary"
                className={css(styles.iconButton)}
                onClick={() => {
                    if (props.confirm) {
                        props.confirm();
                    }
                }}
            >
                {t("filters.search")}
                <SearchOutlined />
            </Button>
        </div>
    );
};

export const getSearchSelectFilter = (options?: IOptions) => ({
    filterDropdown: (props: FilterDropdownProps) => (
        <div>
            {options && options.type === "date" && (
                <CustomDateFilterDropdown
                    {...props}
                    initialValue={
                        options &&
                        options.initialValues &&
                        options.initialValues.length
                            ? options.initialValues[0]
                            : undefined
                    }
                />
            )}
            {options && options.type === "bool" && (
                <CustomFilterDropdown
                    {...props}
                    allItems={[
                        {
                            text: t("forms.yes"),
                            value: "true",
                        },
                        {
                            text: t("forms.no"),
                            value: "false",
                        },
                    ]}
                    type="text"
                    initialValues={
                        options && options.initialValues
                            ? options.initialValues
                            : undefined
                    }
                />
            )}
            {(!options ||
                !options.type ||
                options.type === "number" ||
                options.type === "text") && (
                <CustomFilterDropdown
                    {...props}
                    allItems={options ? options.items : undefined}
                    type={options && options.type ? options.type : "text"}
                    initialValues={
                        options && options.initialValues
                            ? options.initialValues
                            : undefined
                    }
                />
            )}
        </div>
    ),
    filterIcon: (filtered: boolean) => (
        <SearchOutlined className={filtered ? "filtered-icon" : ""} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
            setTimeout(() => {
                if (searchInput) {
                    searchInput.select();
                }
            });
        }
    },
});
