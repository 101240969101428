import * as React from "react";
import { BaseTable } from "../../../../base/components";
import { Customer, Product } from "../../../models";
import { EditProductForm } from "../../EditProductForm";
import { Typography } from "antd";
import { t } from "../../../../../translation";
import { BaseFormModal } from "../../../../base/components/BaseFormModal";

interface IProps {
    customer: Customer;
    reload: () => void;
}

export const ProductTab: React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState<Product | null>(null);

    return (
        <>
            <BaseFormModal
                subState="products"
                create={false}
                visible={!!isOpen}
                onClose={() => setIsOpen(null)}
                width="860px"
                editForm={
                    <EditProductForm
                        product={isOpen}
                        customerId={props.customer.id}
                        onClose={() => setIsOpen(null)}
                    />
                }
            />
            <BaseTable<Product>
                subState="products"
                dataSource={props.customer.products}
                height="400px"
                onEdit={(product) => setIsOpen(product)}
            />
            <div style={{ marginTop: "2em" }}>
                <Typography.Title level={4}>
                    {t("navigation.create")}
                </Typography.Title>
                <EditProductForm
                    customerId={props.customer.id}
                    product={null}
                    onClose={() => props.reload()}
                />
            </div>
        </>
    );
};
