import { BaseModel } from "../../normalized/models";
import { Product } from "./Product";
import { Type } from "class-transformer";

export class Customer extends BaseModel {
    id: string;
    name: string;
    address: string;
    country: string;
    phone: string;
    invoiceEmail: string;
    invoiceSubTotals: boolean;
    bioNumber: string;
    vatNumber: string;
    cocNumber: string;
    contactPersonName: string;
    contactPersonEmail: string;
    isActive: boolean;
    hideOnDashboard: boolean;
    noteOnPdf: string;

    @Type(() => Product)
    products: Product[];
}
