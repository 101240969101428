export const tenantsTranslationNl = {
    title: "Omgeving",
    tableTitle: "Beheer omgeving",
    fields: {
        qsId: "QS ID",
        ogkNumber: "OGK nummer",
        ggnNumber: "GGN nummer",
        skalNumber: "SKAL nummer",
    },
};
