import * as React from "react";
import { Avatar, Menu } from "antd";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { useHistory, useLocation } from "react-router-dom";
import {
    getAuthenticatedRoutes,
    getMatchedKeys,
} from "../../../../shared/routes";
import {
    AppstoreOutlined,
    BarsOutlined,
    DashboardOutlined,
    EuroOutlined,
    SettingOutlined,
} from "@ant-design/icons/lib";

import "./baseMenu.less";
import { t } from "../../../../translation";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    right: {
        float: "right",
    },
});

export const BaseMenu: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const logoutAction = useStoreActions((actions) => actions.auth.logout);
    const currentUser = useStoreState((state) => state.auth.currentUser);

    const authenticatedRoutes = getAuthenticatedRoutes();

    return (
        <>
            <Menu
                mode="horizontal"
                theme="light"
                onClick={(value) => {
                    const path = value.keyPath[0];
                    if (path.startsWith("item_")) {
                        return;
                    }
                    history.push(`${path}`);
                }}
                selectedKeys={getMatchedKeys(location)}
                className="menu-base"
            >
                <Menu.Item key={authenticatedRoutes.root.path}>
                    <DashboardOutlined />
                    Dashboard
                </Menu.Item>

                <Menu.Item key={authenticatedRoutes.orders.path}>
                    <BarsOutlined />
                    {t("navigation.orders")}
                </Menu.Item>

                <Menu.Item key={authenticatedRoutes.invoices.path}>
                    <EuroOutlined />
                    {t("navigation.invoices")}
                </Menu.Item>

                <Menu.Item key={authenticatedRoutes.customers.path}>
                    <BarsOutlined />
                    {t("navigation.customers")}
                </Menu.Item>

                <Menu.Item key={authenticatedRoutes.tenants.path}>
                    <AppstoreOutlined />
                    {t("navigation.tenants")}
                </Menu.Item>

                {currentUser?.isSuperuser && (
                    <Menu.Item key="/manage">
                        <SettingOutlined />
                        {t("navigation.admin")}
                    </Menu.Item>
                )}

                <Menu.Item
                    key={authenticatedRoutes.logout.path}
                    onClick={() => {
                        logoutAction().then((loggedOut) => {
                            if (loggedOut) {
                                history.push("/");
                                window.location.reload();
                            }
                        });
                    }}
                >
                    {t("navigation.logout")}
                </Menu.Item>
                <Menu.Item className={css(styles.right)} disabled={true}>
                    <Avatar size="large" className="menu-ant-avatar">
                        {currentUser?.firstName
                            ? currentUser.firstName[0]
                            : currentUser?.username[0]}
                    </Avatar>
                </Menu.Item>
                <Menu.Item
                    className={css(styles.right)}
                    onClick={() => window.open("https://inogo.nl/")}
                >
                    Ontwikkeld door: InoGo Software
                </Menu.Item>
            </Menu>
            <div className="menu-filler" />
        </>
    );
};
