import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { ColumnGenerator } from "../../ColumnGenerator";
import { FilterData } from "../../../normalizer";
import { Customer } from "../../../../modules/customers/models";

interface ICustomerRow {
    key: string;
    name: string;
}

const getDataSource = (items: Customer[]): ICustomerRow[] => {
    return items.map((item) => ({
        key: item.id,
        name: item.name,
    }));
};

const getColumns = (
    filterData: FilterData | null
): AntColumnProps<ICustomerRow>[] => {
    const columnGenerator = new ColumnGenerator<ICustomerRow>(
        "customers",
        filterData,
        []
    );

    columnGenerator.generateColumn("name", {
        enableFilter: true,
        enableSorter: true,
    });

    return columnGenerator.columns;
};

export const customerTableDefinition: ITableDefinition<
    ICustomerRow,
    Customer
> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as ICustomerRow,
    getColumns,
    getDataSource,
};
