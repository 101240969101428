import { authState } from "../modules/auth/state";
import { normalizeState } from "../modules/normalized/state";
import { customerState } from "../modules/customers/state";

export const storeModel = {
    auth: authState,
    normalize: normalizeState,
    customers: customerState,
};

export type RootState = typeof storeModel;
