import * as React from "react";
import { BaseLayout } from "../../base/components/BaseLayout";
import { t } from "../../../translation";
import { BaseTable } from "../../base/components";
import { Customer } from "../models";
import { getAuthenticatedPath } from "../../../shared/routes";
import { useHistory } from "react-router-dom";

export const CustomerOverviewScreen: React.FC = () => {
    const history = useHistory();

    return (
        <BaseLayout>
            <BaseTable<Customer>
                subState="customers"
                title={t("customers.title")}
                enableWebSockets={true}
                onEdit={(customer) =>
                    history.push(
                        getAuthenticatedPath("customers-detail", {
                            id: customer.id,
                        })
                    )
                }
                showActionBar={true}
            />
        </BaseLayout>
    );
};
