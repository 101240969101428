export const customersTranslationNl = {
    title: "Klanten",
    singular: "Klant",
    fields: {
        id: "id",
        name: "Naam",
        address: "Adres",
        country: "Land",
        phone: "Telefoon",
        invoiceEmail: "Facturatie email",
        invoiceSubTotals: "Facturatie sub totalen",
        bioNumber: "Bio nummer",
        vatNumber: "BTW nummer",
        cocNumber: "KVK nummer",
        contactPersonName: "Naam contact persoon",
        contactPersonEmail: "Email contact persoon",
        isActive: "Actief",
        hideOnDashboard: "Niet weergeven op dashboard",
        noteOnPdf: "Notitie op PDF",
    },
};
