import { BaseModel } from "../../normalized/models";

export class InvoiceRow extends BaseModel {
    id: string;

    description: string;
    quantity: number;
    unitPrice: number;
    date: string | null;
    vat: number;

    totalExcl(): number {
        return this.quantity * this.unitPrice;
    }

    totalVat(): number {
        return this.totalExcl() * (this.vat / 100);
    }

    totalIncl(): number {
        return this.totalExcl() + this.totalVat();
    }
}
