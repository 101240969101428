import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { INormalizeState } from "../state";
import { logger } from "../../../shared/logging";
import {
    getDefinitionForSubState,
    NormalizeItemBase,
    SubState,
} from "../../../shared/normalizer";
import { Notifier } from "../../../shared/functions";
import { ID } from "../../../shared/interfaces";

interface IPayload {
    subState: SubState;
    id: ID;
    data: object;
    originalObject?: object;
}

/**
 * Generic function to update an item and update it in the normalized state
 */
export const updateItemThunk: Thunk<
    INormalizeState,
    IPayload,
    any,
    RootState,
    Promise<NormalizeItemBase>
> = thunk((actions, payload) => {
    actions.updateItemBegin({ subState: payload.subState });
    const definition = getDefinitionForSubState(payload.subState);

    return HttpClient.patch<NormalizeItemBase>(
        `/api/${definition.baseUrl}/${payload.id.toString()}/`,
        payload.data,
        {
            schema: definition.schema,
            original: payload.originalObject,
        }
    )
        .then((data) => {
            Notifier.success("http.crud.update.success");
            actions.updateItemSuccess({ data, subState: payload.subState });
            return data;
        })
        .catch((error) => {
            logger.error(error.response);
            Notifier.error("http.crud.update.error");
            actions.updateItemFailure({ error, subState: payload.subState });
            logger.info(error.response);
            throw error;
        });
});
