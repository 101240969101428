import { HomeScreen, NotFoundScreen } from "../../modules/base/screens";
import { getRoutes, IRoute } from "./sharedRoutes";
import {
    GroupOverviewScreen,
    UserEditScreen,
    UserOverviewScreen,
} from "../../modules/user/screens";
import { t } from "../../translation";
import { ID } from "../interfaces";
import { CustomerOverviewScreen } from "../../modules/customers/screens/CustomerOverviewScreen";
import { CustomerEditScreen } from "../../modules/customers/screens/CustomerEditScreen";
import { CustomerDetailScreen } from "../../modules/customers/screens/CustomerDetailScreen";
import { OrderOverviewScreen } from "../../modules/orders/screens/OrderOverviewScreen";
import { OrderDetailScreen } from "../../modules/orders/screens/OrderDetailScreen";
import { OrderEditScreen } from "../../modules/orders/screens/OrderEditScreen";
import { InvoiceOverviewScreen } from "../../modules/invoices/screens/InvoiceOverviewScreen";
import { InvoiceEditScreen } from "../../modules/invoices/screens/InvoiceEditScreen";
import qs from "qs";
import { ManageScreen } from "../../modules/manage/screens/ManageScreen";
import { TenantEditScreen } from "../../modules/tenant/screens/TenantEditScreen";

type AuthenticatedRoutes =
    | "root"
    | "manage"
    | "customers"
    | "customers-new"
    | "customers-detail"
    | "orders"
    | "orders-new"
    | "orders-detail"
    | "invoices"
    | "invoices-new"
    | "invoices-detail"
    | "users"
    | "users-new"
    | "users-detail"
    | "groups"
    | "tenants-detail"
    | "logout"
    | "notFound";

export const getAuthenticatedRoutes = (): {
    [key: string]: IRoute<AuthenticatedRoutes>;
} => ({
    root: {
        component: HomeScreen,
        exact: true,
        path: "/",
        key: "root",
        title: "",
    },
    manage: {
        component: ManageScreen,
        exact: true,
        path: "/manage",
        key: "manage",
        title: t("navigation.admin"),
    },
    customers: {
        component: CustomerOverviewScreen,
        exact: false,
        path: "/customers",
        key: "customers",
        title: t("navigation.customers"),
        childs: [
            {
                component: CustomerEditScreen,
                exact: true,
                path: "/new",
                key: "customers-new",
                title: t("navigation.create"),
            },
            {
                component: CustomerDetailScreen,
                exact: true,
                path: "/:id",
                key: "customers-detail",
                title: t("navigation.edit"),
            },
        ],
    },
    orders: {
        component: OrderOverviewScreen,
        exact: false,
        path: "/orders",
        key: "orders",
        title: t("navigation.orders"),
        childs: [
            {
                component: OrderEditScreen,
                exact: true,
                path: "/new",
                key: "orders-new",
                title: t("navigation.create"),
            },
            {
                component: OrderDetailScreen,
                exact: true,
                path: "/:id",
                key: "orders-detail",
                title: t("navigation.edit"),
            },
        ],
    },
    invoices: {
        component: InvoiceOverviewScreen,
        exact: false,
        path: "/invoices",
        key: "invoices",
        title: t("navigation.invoices"),
        childs: [
            {
                component: InvoiceEditScreen,
                exact: true,
                path: "/new",
                key: "invoices-new",
                title: t("navigation.create"),
            },
            {
                component: InvoiceEditScreen,
                exact: true,
                path: "/:id",
                key: "invoices-detail",
                title: t("navigation.edit"),
            },
        ],
    },
    users: {
        component: UserOverviewScreen,
        exact: false,
        path: "/users",
        key: "users",
        title: t("navigation.users"),
        childs: [
            {
                component: UserEditScreen,
                exact: true,
                path: "/new",
                key: "users-new",
                title: t("navigation.create"),
            },
            {
                component: UserEditScreen,
                exact: true,
                path: "/:id",
                key: "users-detail",
                title: t("navigation.edit"),
            },
        ],
    },
    groups: {
        component: GroupOverviewScreen,
        exact: true,
        path: "/groups",
        key: "groups",
        title: t("navigation.groups"),
    },
    tenants: {
        component: TenantEditScreen,
        exact: true,
        path: "/tenant",
        key: "tenants-detail",
        title: t("navigation.tenants"),
    },
    logout: {
        component: undefined,
        path: "/logout",
        key: "logout",
        title: t("navigation.logout"),
    },
    notFound: {
        component: NotFoundScreen,
        path: undefined,
        key: "notFound",
        title: t("navigation.notFound"),
    },
});

export const getAuthenticatedPath = (
    key: AuthenticatedRoutes,
    ids?: { [key: string]: ID },
    params?: { [key: string]: string }
): string => {
    const routes = getRoutes(getAuthenticatedRoutes());
    if (key in routes && routes[key].path) {
        let route = routes[key].path;

        if (!route) {
            return "/";
        }

        if (ids) {
            for (const [_key, value] of Object.entries(ids)) {
                route = route.replace(`:${_key}`, value.toString());
            }
        }

        if (params) {
            route += qs.stringify(params, { addQueryPrefix: true });
        }

        return route;
    }
    return "/";
};
