import * as React from "react";
import { Invoice } from "../../models";
import {
    BaseForm,
    FormDatePickerItem,
    FormInputItem,
    FormInputNumberItem,
    FormSelectItem,
} from "../../../base/components/BaseForm";
import {
    getInvoiceInitialValues,
    getInvoiceRowInitialValues,
    getInvoiceValidationSchema,
    IInvoiceForm,
    IInvoiceRowForm,
    invoiceToValues,
} from "../../forms/invoiceInitialValues";
import { t } from "../../../../translation";
import { useNormalizedState } from "../../../base/hooks";
import { Customer } from "../../../customers/models";
import { Alert, Button, Typography } from "antd";
import { AddRowButton, RemoveRowButton } from "formik-antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FormSubmitButton } from "../../../base/components/BaseForm/FormSubmitButton/FormSubmitButton";
import { SendInvoiceButton } from "../SendInvoiceButton";

interface IProps {
    invoice: Invoice | null;
    onClose: (values: IInvoiceForm) => void;
}

export const EditInvoiceForm: React.FC<IProps> = (props) => {
    const customers = useNormalizedState<Customer>("customers").data;

    const isSent = !!props.invoice && !!props.invoice.sentAt;

    return (
        <BaseForm<IInvoiceForm>
            subState="invoices"
            initialValues={
                props.invoice
                    ? invoiceToValues(props.invoice)
                    : getInvoiceInitialValues()
            }
            onClose={(values) => props.onClose(values)}
            validationSchema={getInvoiceValidationSchema()}
            readonly={isSent}
            fast={true}
            onRender={(helpers) => (
                <>
                    {isSent && (
                        <Alert
                            style={{ marginBottom: "1em" }}
                            closable={false}
                            type="warning"
                            message={t("invoices.alreadySent")}
                        />
                    )}

                    <FormSelectItem
                        label={t("customers.singular")}
                        withFilter={true}
                        name="customer"
                        allowClear={true}
                        options={customers.map((customer) => ({
                            label: customer.name,
                            value: customer.id,
                        }))}
                    />

                    <FormDatePickerItem
                        label={t("invoices.fields.date")}
                        name="date"
                        picker="date"
                        allowClear={false}
                    />

                    <FormDatePickerItem
                        label={t("invoices.fields.paymentDue")}
                        name="paymentDue"
                        picker="date"
                        allowClear={false}
                    />

                    <FormInputItem
                        label={t("invoices.fields.reference")}
                        name="reference"
                    />
                    <FormInputItem
                        label={t("invoices.fields.period")}
                        name="period"
                    />

                    <Typography.Title level={3}>
                        {t("invoices.invoiceRows.title")}
                    </Typography.Title>

                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>
                                    {t(
                                        "invoices.invoiceRows.fields.description"
                                    )}
                                </th>
                                <th>{t("invoices.invoiceRows.fields.date")}</th>
                                <th>
                                    {t("invoices.invoiceRows.fields.quantity")}
                                </th>
                                <th>
                                    {t("invoices.invoiceRows.fields.unitPrice")}
                                </th>
                                <th>{t("invoices.invoiceRows.fields.vat")}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {helpers.values.invoiceRows.map((_, index) => (
                                <tr key={index}>
                                    <td>
                                        <FormInputItem
                                            label=""
                                            name={`invoiceRows.${index}.description`}
                                        />
                                    </td>
                                    <td>
                                        <FormDatePickerItem
                                            label=""
                                            name={`invoiceRows.${index}.date`}
                                            allowClear={true}
                                            picker="date"
                                        />
                                    </td>
                                    <td>
                                        <FormInputNumberItem
                                            label=""
                                            name={`invoiceRows.${index}.quantity`}
                                        />
                                    </td>
                                    <td>
                                        <FormInputNumberItem
                                            label=""
                                            name={`invoiceRows.${index}.unitPrice`}
                                        />
                                    </td>
                                    <td>
                                        <FormInputNumberItem
                                            label=""
                                            name={`invoiceRows.${index}.vat`}
                                        />
                                    </td>
                                    <td>
                                        <RemoveRowButton
                                            name="invoiceRows"
                                            index={index}
                                            type="danger"
                                        >
                                            <DeleteOutlined />
                                        </RemoveRowButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <AddRowButton<IInvoiceRowForm>
                            name="invoiceRows"
                            createNewRow={() => getInvoiceRowInitialValues()}
                        >
                            {t("buttons.add")}
                        </AddRowButton>
                    </div>
                </>
            )}
            disableSubmitButton={true}
            extraActions={(helpers) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1em",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <Button
                            onClick={async () => {
                                if (!helpers.values.id) {
                                    return;
                                }

                                helpers.submitForm().then(() => {
                                    window.open(
                                        `/api/invoices/${helpers.values.id}/pdf/`
                                    );
                                });
                            }}
                        >
                            {t("buttons.download")}
                        </Button>
                        {props.invoice && (
                            <div style={{ marginLeft: "1em" }}>
                                <SendInvoiceButton invoice={props.invoice} />
                            </div>
                        )}
                    </div>
                    <FormSubmitButton />
                </div>
            )}
        />
    );
};
