import * as React from "react";
import { BaseNotFound } from "../../base/components/BaseNotFound";
import { BaseLayout } from "../../base/components/BaseLayout";
import { useTenant } from "../../base/hooks";
import { EditTenantForm } from "../components/EditTenantForm";

export const TenantEditScreen: React.FC = () => {
    const tenant = useTenant();

    return (
        <BaseLayout>
            {tenant ? <EditTenantForm tenant={tenant} /> : <BaseNotFound />}
        </BaseLayout>
    );
};
