import { Schema, schema } from "normalizr";
import { User, Group } from "../../modules/auth/models";
import { INormalizedDefinition, SubState } from "./interfaces";
import { Customer, Product, ProductRate } from "../../modules/customers/models";
import { Order } from "../../modules/orders/models";
import { Invoice } from "../../modules/invoices/models";
import { Tenant } from "../../modules/tenant/models";

// Normalizer Schema's
export const groupSchema = new schema.Entity<Group>("groups");
export const userSchema = new schema.Entity<User>("users");
export const customerSchema = new schema.Entity<Customer>("customers");
export const productSchema = new schema.Entity<Product>("products");
export const productRateSchema = new schema.Entity<ProductRate>("productRates");
export const orderSchema = new schema.Entity<Product>("orders");
export const invoiceSchema = new schema.Entity<Invoice>("invoices");
export const tenantSchema = new schema.Entity<Tenant>("tenants");

// Separate lines for definitions to prevent circular references
userSchema.define({
    groups: [groupSchema],
});

customerSchema.define({
    products: [productSchema],
});

productSchema.define({
    productRates: [productRateSchema],
});

customerSchema.define({
    products: [productSchema],
});

orderSchema.define({
    customer: customerSchema,
});

invoiceSchema.define({
    customer: customerSchema,
});

export const globalSchema: { [key in SubState]: Schema[] } = {
    users: [userSchema],
    groups: [groupSchema],
    customers: [customerSchema],
    products: [productSchema],
    productRates: [productRateSchema],
    orders: [orderSchema],
    invoices: [invoiceSchema],
    tenants: [tenantSchema],
};

export const globalNormalizedState: {
    [key in SubState]: INormalizedDefinition;
} = {
    users: {
        model: User,
        schema: userSchema,
        baseUrl: "users",
    },
    groups: {
        model: Group,
        schema: groupSchema,
        baseUrl: "groups",
    },
    customers: {
        model: Customer,
        schema: customerSchema,
        baseUrl: "customers",
    },
    products: {
        model: Product,
        schema: productSchema,
        baseUrl: "products",
    },
    productRates: {
        model: ProductRate,
        schema: productSchema,
        baseUrl: "product_rates",
    },
    orders: {
        model: Order,
        schema: orderSchema,
        baseUrl: "orders",
        requiredSubStates: ["customers"],
    },
    invoices: {
        model: Invoice,
        schema: invoiceSchema,
        baseUrl: "invoices",
        requiredSubStates: ["customers"],
    },
    tenants: {
        model: Tenant,
        schema: tenantSchema,
        baseUrl: "tenants",
    },
};
