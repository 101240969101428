export const orderTranslationNl = {
    title: "Palletbonnen",
    singular: "Palletbon",
    create: "Palletbon aanmaken",
    ordersWithoutInvoice: "Palletbonnen zonder factuur",
    totalPdf: "Download totaalbon (PDF)",
    totalWeekOrder: "Week totaalbon",
    fields: {
        id: "id",
        customer: "Klant",
        deliveryDate: "Afleverdatum",
        pickDate: "Plukdatum",
        celNumber: "Celnummer",
        orderRows: "Producten",
        orderNr: "Palletbon #",
        isInvoiced: "Gefactureerd",
        invoices: "Facturen",
        orderAmount: "Palletbon (aantal)",
        dates: "Datums",
        comment: "Opmerkingen",
        week: "Week",
    },
};
