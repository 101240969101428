import * as React from "react";
import { Form, Select, SelectProps } from "formik-antd";
// tslint:disable-next-line:no-submodule-imports
import { FormItemProps } from "formik-antd/src/form-item/index";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import { FormContext } from "../index";

interface IProps {
    label: string;
    name: string;
    extraItemProps?: Partial<FormItemProps>;
    withFilter?: boolean;
}

export const FormSelectItem: React.FC<IProps & SelectProps> = (props) => {
    const formContext = React.useContext(FormContext);

    const filterOption = (
        inputValue: string,
        option: any | undefined
    ): boolean => {
        if (!option) {
            return false;
        }

        if (option.label) {
            return (
                String(option.label)
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
            );
        }

        return option
            ? String(option.props.children)
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
            : false;
    };

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            {...props.extraItemProps}
        >
            <Select
                disabled={formContext.readOnly}
                filterOption={props.withFilter ? filterOption : undefined}
                showSearch={!!props.withFilter}
                {...props}
                name={props.name}
                data-cy={`input-${toSnake(props.name).replace("_", "-")}`}
                fast={formContext.fast}
            >
                {props.children}
            </Select>
        </Form.Item>
    );
};
