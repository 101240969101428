import * as React from "react";
import { HttpClient } from "../../../../shared/networking";
import { Spin } from "antd";
import { useNormalizedState } from "../../../base/hooks";
import { Customer } from "../../../customers/models";
import { Link } from "react-router-dom";
import { getAuthenticatedPath } from "../../../../shared/routes";
import { CreateInvoiceOrdersButton } from "../../../invoices/components/CreateInvoiceOrdersButton";
import { t } from "../../../../translation";
import { useStoreState } from "../../../../store/hooks";
import { useDebouncedCallback } from "use-debounce";

interface IOrdersWithoutInvoice {
    customer: string;
    orders: string[];
    week: number;
}

export const OrdersWithoutInvoice: React.FC = () => {
    const [customersWithoutInvoice, setData] = React.useState<
        IOrdersWithoutInvoice[] | null
    >(null);
    const ordersDone = useStoreState(
        (state) => state.normalize.meta.orders.post.done
    );
    const debounce = useDebouncedCallback(
        () => fetchOrdersWithoutInvoice(),
        1000
    );

    const { data: customers } = useNormalizedState<Customer>("customers");

    const fetchOrdersWithoutInvoice = () => {
        HttpClient.get<IOrdersWithoutInvoice[]>(
            "/api/orders/without_invoice/"
        ).then((res) => {
            setData(res);
        });
    };

    React.useEffect(() => {
        debounce();
    }, [ordersDone]);

    if (!customersWithoutInvoice) {
        return <Spin />;
    }

    return (
        <table style={{ width: "100%" }}>
            <thead>
                <tr>
                    <th>{t("orders.fields.customer")}</th>
                    <th>{t("orders.fields.orderAmount")}</th>
                    <th>{t("orders.fields.week")}</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {customersWithoutInvoice.map((row) => (
                    <tr key={row.customer}>
                        <td>
                            <Link
                                to={getAuthenticatedPath("customers-detail", {
                                    id: row.customer,
                                })}
                            >
                                {
                                    customers.find(
                                        (customer) =>
                                            customer.id === row.customer
                                    )?.name
                                }
                            </Link>
                        </td>
                        <td>
                            <Link
                                to={getAuthenticatedPath(
                                    "customers-detail",
                                    { id: row.customer },
                                    { tab: "orders" }
                                )}
                            >
                                {row.orders.length}
                            </Link>
                        </td>
                        <td>{row.week}</td>
                        <td>
                            <CreateInvoiceOrdersButton orderIds={row.orders} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
