import * as React from "react";
import { Product } from "../../models";
import {
    BaseForm,
    FormDatePickerItem,
    FormInputItem,
    FormInputNumberItem,
} from "../../../base/components/BaseForm";
import { t } from "../../../../translation";
import {
    getProductInitialValues,
    getProductValidationSchema,
    IProductForm,
    productToValues,
} from "../../forms/productInitialValues";
import { BaseTable } from "../../../base/components";
import { Divider } from "antd";

interface IProps {
    customerId: string;
    product: Product | null;
    onClose?: () => void;
}

export const EditProductForm: React.FC<IProps> = (props) => {
    return (
        <BaseForm<IProductForm>
            subState="products"
            initialValues={
                props.product
                    ? productToValues(props.product)
                    : getProductInitialValues(props.customerId)
            }
            validationSchema={getProductValidationSchema()}
            onClose={props.onClose}
            allValues={true}
        >
            <FormInputItem label={t("products.fields.name")} name="name" />
            <FormInputNumberItem
                label={t("products.fields.weight")}
                name="weight"
                step={0.01}
                min={0}
            />
            <FormInputNumberItem
                label={t("products.fields.vat")}
                name="vat"
                step={1}
                min={0}
            />
            {!!props.product ? (
                <div>
                    <Divider />
                    <BaseTable
                        subState="productRates"
                        dataSource={props.product.productRates}
                        disableDelete={true}
                        disableSelection={true}
                    />

                    <div style={{ marginTop: "1em" }}>
                        <FormInputNumberItem
                            label={t("products.fields.price")}
                            name="price"
                            step={0.01}
                        />
                        <FormDatePickerItem
                            label={t("products.fields.date")}
                            name="date"
                            picker="date"
                            allowClear={false}
                        />
                    </div>
                </div>
            ) : (
                <FormInputNumberItem
                    label={t("products.fields.price")}
                    name="price"
                    step={0.01}
                />
            )}
        </BaseForm>
    );
};
