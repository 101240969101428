import * as React from "react";
import "./Popup.less";

export interface IPopupProps {
    record: any;
    visible: boolean;
    x: number;
    y: number;
    content: React.ReactNode;
}

export const Popup: React.FC<IPopupProps> = (props) => (
    <ul className="popup" style={{ left: `${props.x}px`, top: `${props.y}px` }}>
        {props.content}
    </ul>
);
