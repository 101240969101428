import * as React from "react";
import { Modal } from "antd";
import { SubState } from "../../../../shared/normalizer";
import { BasePermissionWrapper } from "../BasePermissionWrapper";
import { t } from "../../../../translation";

interface IProps {
    subState: SubState;
    create: boolean;
    visible: boolean;
    onClose: () => void;
    editForm: React.ReactNode;
    width?: string;
}

export const BaseFormModal: React.FC<IProps> = (props) => {
    return (
        <Modal
            title={props.create ? t("forms.edit") : t("forms.add")}
            visible={props.visible}
            centered={true}
            footer={null}
            onCancel={() => props.onClose()}
            destroyOnClose={true}
            width={props.width}
            bodyStyle={{
                width: props.width,
            }}
        >
            <BasePermissionWrapper
                subState={props.subState}
                permission="change"
                showMessage={true}
            >
                {props.editForm}
            </BasePermissionWrapper>
        </Modal>
    );
};
