import { BaseModel } from "../../normalized/models";
import { Type } from "class-transformer";
import { OrderRow } from "./OrderRow";
import { Customer } from "../../customers/models";

export class Order extends BaseModel {
    id: string;

    @Type(() => Customer)
    customer: Customer;

    orderNr: number;
    deliveryDate: string;
    pickDate: string;
    celNumber: number;

    comment: string;

    @Type(() => OrderRow)
    orderRows: OrderRow[];

    invoices: {
        id: string;
        invoiceNumber: string;
    }[];

    isInvoiced(): boolean {
        return this.invoices.length > 0;
    }
}
