import * as React from "react";
import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { ColumnGenerator } from "../../ColumnGenerator";
import { FilterData } from "../../../normalizer";
import { Order } from "../../../../modules/orders/models";
import moment from "moment";
import { DATE_FORMAT } from "../../../functions";
import { Link } from "react-router-dom";
import { getAuthenticatedPath } from "../../../routes";

interface IOrderRow {
    key: string;
    orderNr: number;
    customer: string;
    pickDate: string;
    deliveryDate: string;
    celNumber: number;
    isInvoiced: boolean;
    invoices: { id: string; invoiceNumber: string }[];
}

const getDataSource = (items: Order[]): IOrderRow[] => {
    return items.map((item) => ({
        key: item.id,
        orderNr: item.orderNr,
        customer: item.customer ? item.customer.name : "",
        pickDate: moment(item.pickDate).format(DATE_FORMAT),
        deliveryDate: moment(item.deliveryDate).format(DATE_FORMAT),
        celNumber: item.celNumber,
        isInvoiced: item.isInvoiced(),
        invoices: item.invoices,
    }));
};

const getColumns = (
    filterData: FilterData | null
): AntColumnProps<IOrderRow>[] => {
    const columnGenerator = new ColumnGenerator<IOrderRow>(
        "orders",
        filterData,
        []
    );

    columnGenerator.generateColumn("orderNr", {
        enableSorter: true,
        enableFilter: true,
        type: "number",
    });

    columnGenerator.generateColumn("customer", {
        enableFilter: true,
    });

    columnGenerator.generateColumn("pickDate", {
        type: "date",
        enableSorter: true,
        enableFilter: true,
    });

    columnGenerator.generateColumn("deliveryDate", {
        type: "date",
        enableSorter: true,
        enableFilter: true,
    });

    columnGenerator.generateColumn("celNumber", {
        type: "number",
        enableSorter: true,
        enableFilter: true,
    });

    columnGenerator.generateColumn("isInvoiced", {
        type: "bool",
        enableFilter: true,
    });

    columnGenerator.generateColumn("invoices", {
        render: (invoices) =>
            invoices.map((invoice) => (
                <Link
                    key={invoice.id}
                    to={getAuthenticatedPath("invoices-detail", {
                        id: invoice.id,
                    })}
                >
                    {invoice.invoiceNumber}
                </Link>
            )),
    });

    return columnGenerator.columns;
};

export const orderTableDefinition: ITableDefinition<IOrderRow, Order> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as IOrderRow,
    getColumns,
    getDataSource,
};
