import * as React from "react";
import { BaseLayout } from "../../base/components/BaseLayout";

export const ManageScreen: React.FC = () => (
    <BaseLayout fluid={true} bodyStyle={{ padding: 0 }}>
        <div style={{ width: "100%", height: "calc(100vh - 185px)" }}>
            <iframe
                title="django-admin"
                style={{ width: "100%", height: "100%", border: 0 }}
                src="/admin/"
            />
        </div>
    </BaseLayout>
);
