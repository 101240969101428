import * as React from "react";
import { Tabs } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import * as qs from "qs";
// tslint:disable-next-line:no-submodule-imports
import { TabsProps } from "antd/es/tabs";
import { StyleSheet, css } from "aphrodite";
import { SubState } from "../../../../shared/normalizer";
import { useCurrentUser } from "../../hooks";
import { PermissionType } from "../../../auth/models";

interface ITab {
    title: React.ReactNode;
    key: string;
    content: React.ReactNode;
    subState?: SubState;
    permissionType?: PermissionType;
}

interface IProps {
    tabs: ITab[];
}

const styles = StyleSheet.create({
    tab: {
        maxHeight: "100%",
        overflowY: "auto",
    },
});

export const BaseTab: React.FC<IProps & TabsProps> = (props) => {
    const location = useLocation();
    const history = useHistory();
    const keys = props.tabs.map((_tab) => _tab.key);
    const currentUser = useCurrentUser();

    const [tab, setTab] = React.useState<string>(keys[0]);

    React.useEffect(() => {
        if (location.search) {
            const params = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            });

            const tabKey = "tab";
            if (tabKey in params && keys.includes(params[tabKey])) {
                setTab(params[tabKey]);
            }
        }
    }, []);

    const changeTab = (activeKey: string) => {
        history.push({
            search: qs.stringify({ tab: activeKey }),
        });
        setTab(activeKey);
    };

    const tabs = props.tabs.filter((_tab) =>
        _tab.subState
            ? currentUser
                ? currentUser.hasPermission(
                      _tab.subState,
                      _tab.permissionType || "view"
                  )
                : false
            : true
    );

    return (
        <>
            <Tabs
                activeKey={tab}
                onChange={changeTab}
                tabPosition="left"
                size="small"
                {...props}
            >
                {tabs.map((_tab) => (
                    <Tabs.TabPane
                        tab={_tab.title}
                        key={_tab.key}
                        className={css(styles.tab)}
                    >
                        {_tab.content}
                    </Tabs.TabPane>
                ))}
                {props.children}
            </Tabs>
        </>
    );
};
