import * as React from "react";
import { DatePickerProps, Form, DatePicker, FormItemProps } from "formik-antd";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import { FormContext } from "../index";
import {
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    TIME_FORMAT,
} from "../../../../../shared/functions";
import { useFormikContext } from "formik";

interface IProps {
    label: string;
    name: string;
    placeholder?: string;
    extraItemProps?: FormItemProps;
    withTime?: boolean;
}

export const FormDatePickerItem: React.FC<IProps & DatePickerProps> = (
    props
) => {
    const formContext = React.useContext(FormContext);
    const f = useFormikContext();

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            {...props.extraItemProps}
        >
            <DatePicker
                disabled={formContext.readOnly}
                format={props.withTime ? DATE_TIME_FORMAT : DATE_FORMAT}
                {...{
                    ...props,
                    // Put showTime in a dict since formik-antd has an incorrect type
                    showTime: props.withTime
                        ? { format: TIME_FORMAT }
                        : undefined,
                }}
                name={props.name}
                placeholder={(props.placeholder || props.label).toLowerCase()}
                data-cy={`input-${toSnake(props.name).replace("_", "-")}`}
                onChange={(value) => {
                    const cleanedValue = value ? value.toISOString() : null;
                    f.setFieldValue(props.name, cleanedValue);
                }}
                fast={formContext.fast}
            />
        </Form.Item>
    );
};
