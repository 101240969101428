import * as React from "react";
import { useStoreActions } from "../../../store/hooks";

export const AuthGuard: React.FC = () => {
    const getCurrentUserAction = useStoreActions(
        (actions) => actions.auth.getCurrentUser
    );
    const getCurrentTenantAction = useStoreActions(
        (actions) => actions.auth.getCurrentTenant
    );
    const setTokenAction = useStoreActions(
        (actions) => actions.auth.setTokenData
    );

    React.useEffect(() => {
        let token = localStorage.getItem("token");
        const hijackToken = localStorage.getItem("hijackToken");
        getCurrentTenantAction();

        if (!token && hijackToken) {
            // When the hijack session is ended
            localStorage.removeItem("hijackToken");
            token = hijackToken;
            setTokenAction({ token, remember: true });
            getCurrentUserAction();
        } else if (token) {
            // If a token was found in local storage
            setTokenAction({ token, remember: false });
            getCurrentUserAction();
        } else if (hijackToken) {
            // If a hijackToken was found in local storage
            setTokenAction({ token, remember: false });
            getCurrentUserAction();
        } else {
            // If no token was found
            setTokenAction({ token: null, remember: false });
        }
    }, []);

    return null;
};
