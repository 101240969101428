export const productsTranslationEn = {
    title: "Products",
    singular: "Product",
    fields: {
        name: "Name",
        weight: "Weight (KG)",
        price: "Price (per unit)",
        date: "From date (price)",
        vat: "Vat %",
    },
};
