import { ITableDefinition } from "../index";
import { AntColumnProps } from "../../types";
import { ColumnGenerator } from "../../ColumnGenerator";
import { FilterData } from "../../../normalizer";
import { ProductRate } from "../../../../modules/customers/models";
import { formatCurrency } from "../../../functions/currency";
import moment from "moment";
import { API_DATE_FORMAT, DATE_FORMAT } from "../../../functions";

interface IProductRateRow {
    key: string;
    date: string;
    price: string;
}

const getDataSource = (items: ProductRate[]): IProductRateRow[] => {
    return items
        .sort((a, b) => a.date.localeCompare(b.date))
        .map((item) => ({
            key: item.id,
            date: moment(item.date, API_DATE_FORMAT).format(DATE_FORMAT),
            price: formatCurrency(item.price),
        }));
};

const getColumns = (
    filterData: FilterData | null
): AntColumnProps<IProductRateRow>[] => {
    const columnGenerator = new ColumnGenerator<IProductRateRow>(
        "productRates",
        filterData,
        []
    );

    columnGenerator.generateColumn("date");

    columnGenerator.generateColumn("price");

    return columnGenerator.columns;
};

export const productRateTableDefinition: ITableDefinition<
    IProductRateRow,
    ProductRate
> = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    row: {} as IProductRateRow,
    getColumns,
    getDataSource,
};
