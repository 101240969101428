export const productsTranslationNl = {
    title: "Producten",
    singular: "Product",
    fields: {
        name: "Naam",
        weight: "Gewicht (KG)",
        price: "Prijs (per stuk)",
        date: "Vanaf datum (prijs)",
        vat: "BTW %",
    },
};
