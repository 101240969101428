import * as React from "react";
import { BaseDetailScreen } from "../../base/components/BaseDetailScreen";
import { Customer } from "../models";
import { CustomerDetail } from "../components/CustomerDetail";

interface IProps {
    match: { params: { id: string } };
}

export const CustomerDetailScreen: React.FC<IProps> = (props) => {
    return (
        <BaseDetailScreen<Customer>
            match={props.match}
            subState={"customers"}
            detailComponent={(customer) => (
                <CustomerDetail customer={customer} />
            )}
        />
    );
};
