import { Tenant } from "../models";
import { getTypedSchema } from "../../base/helpers/baseFormik";
import * as Yup from "yup";

export interface ITenantForm {
    id: number;
    qsId: string;
    ogkNumber: string;
    ggnNumber: string;
    skalNumber: string;
}

export const tenantToValues = (tenant: Tenant): ITenantForm => ({
    id: tenant.id,
    qsId: tenant.qsId,
    ogkNumber: tenant.ogkNumber,
    ggnNumber: tenant.ggnNumber,
    skalNumber: tenant.skalNumber,
});

export const getTenantValidationSchema = () =>
    getTypedSchema<ITenantForm>({
        id: Yup.number(),
        qsId: Yup.string(),
        ogkNumber: Yup.string(),
        ggnNumber: Yup.string(),
        skalNumber: Yup.string(),
    });
