import * as React from "react";
import { BaseLayout } from "../components/BaseLayout";
import { EditOrderForm } from "../../orders/components/OrderForm/EditOrderForm";
import { Col, Divider, Row, Typography } from "antd";
import { OrdersWithoutInvoice } from "../../orders/components/OrdersWithoutInvoice";
import { t } from "../../../translation";
import { DownloadTotalWeekPdf } from "../../orders/components/ModalTotalWeekPdf";

interface IBlockProps {
    title: string;
}

const Block: React.FC<IBlockProps> = (props) => (
    <Col
        md={24}
        xl={12}
        style={{ border: "1px solid lightgrey", minHeight: "500px" }}
    >
        <Typography.Title level={4}>{props.title}</Typography.Title>

        <Divider />

        {props.children}
    </Col>
);

export const HomeScreen: React.FC = () => (
    <BaseLayout fluid={true}>
        <Row gutter={[24, 24]}>
            <Block title={t("orders.create")}>
                <EditOrderForm
                    order={null}
                    redirectToPdf={true}
                    onClose={(helpers) => helpers.resetForm()}
                />
            </Block>

            <Block title={t("orders.ordersWithoutInvoice")}>
                <OrdersWithoutInvoice />
            </Block>

            <Block title={t("orders.totalWeekOrder")}>
                <DownloadTotalWeekPdf />
            </Block>
        </Row>
    </BaseLayout>
);
