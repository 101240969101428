import * as Yup from "yup";
import { getTypedSchema } from "../../base/helpers/baseFormik";
import { Order } from "../models";
import moment from "moment";

export interface IOrderRowForm {
    id: string | null;
    quantity: number;
    product: string | null;
}

export interface IOrderForm {
    id: string | null;
    customer: string;
    deliveryDate: string;
    pickDate: string;
    celNumber: number;
    comment: string;
    orderRows: IOrderRowForm[];
}

export const getOrderInitialValues = (): IOrderForm => ({
    id: null,
    customer: "",
    deliveryDate: moment().toISOString(),
    pickDate: moment().toISOString(),
    celNumber: 0,
    comment: "",
    orderRows: [{ id: null, quantity: 1, product: null }],
});

export const orderToValues = (order: Order): IOrderForm => {
    return {
        id: order.id,
        customer: order.customer.id,
        deliveryDate: order.deliveryDate,
        pickDate: order.pickDate,
        celNumber: order.celNumber,
        comment: order.comment,
        orderRows: order.orderRows,
    };
};

export const getOrderValidationSchema = () =>
    getTypedSchema<IOrderForm>({
        id: Yup.string().nullable(),
        customer: Yup.string().required(),
        deliveryDate: Yup.string().required(),
        celNumber: Yup.number().required(),
        pickDate: Yup.string().required(),
        comment: Yup.string(),
        orderRows: Yup.array().of(
            Yup.object().shape({
                id: Yup.string().nullable(),
                quantity: Yup.number().required(),
                product: Yup.string()
                    .nullable()
                    .required(),
            })
        ),
    });
