// Import reflect-metadata to make sure class-transformer works (https://github.com/typestack/class-transformer)
import "reflect-metadata";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { App } from "./App";
import { logger } from "./shared/logging";
import { getSentryDsn } from "./shared/functions";
import { initializeI18, t } from "./translation";

(window as any).t = t;

const sentryDsn = getSentryDsn();
if (sentryDsn) {
    logger.info("Initializing Sentry");
    Sentry.init({ dsn: sentryDsn });
}

initializeI18();

ReactDOM.render(<App />, document.getElementById("root"));
