import * as React from "react";
import { Button } from "antd";
import { useStoreState } from "../../../../store/hooks";
import { t } from "../../../../translation";
import * as qs from "qs";

export const DownloadTotalPdfButton: React.FC = () => {
    const selectedOrderIds = useStoreState(
        (state) => state.normalize.meta.orders.selectedIds
    );

    const download = () => {
        const params = qs.stringify(
            {
                ids: selectedOrderIds,
            },
            {
                addQueryPrefix: true,
                arrayFormat: "repeat",
            }
        );

        window.open(`/api/orders/multiple_pdf/${params}`);
    };

    return (
        <Button
            onClick={() => download()}
            disabled={selectedOrderIds.length === 0}
        >
            {t("orders.totalPdf")} [{selectedOrderIds.length}]
        </Button>
    );
};
