import * as React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { BaseMenu } from "../index";
import { useStoreState } from "../../../../store/hooks";
import { isAuthenticated } from "../../../auth/selectors";
import { Spin } from "antd";
import {
    getAuthenticatedRoutes,
    getRoutes,
    getUnAuthenticatedRoutes,
} from "../../../../shared/routes";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    loadingWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
});

const getSwitch = (isLoggedIn: boolean) => {
    const routes = getRoutes(
        isLoggedIn ? getAuthenticatedRoutes() : getUnAuthenticatedRoutes()
    );

    return (
        <Switch>
            {Object.values(routes).map((route) => (
                <Route key={route.key} {...route} />
            ))}
        </Switch>
    );
};

export const BaseRouter: React.FC = () => {
    const history = useHistory();
    const isLoggedIn = useStoreState(isAuthenticated);
    const isLoading = useStoreState(
        (state) =>
            state.auth.getCurrentUserLoading ||
            state.auth.getCurrentTenantLoading
    );
    const isDone = useStoreState((state) => state.auth.setTokenDone);

    if (isLoggedIn && history.location.pathname.endsWith("/login")) {
        history.push("/");
    }

    if (isLoggedIn) {
        return (
            <>
                <BaseMenu />
                {getSwitch(isLoggedIn)}
            </>
        );
    } else if (isLoading || !isDone) {
        return (
            <div className={css(styles.loadingWrapper)}>
                <Spin size="large" />
            </div>
        );
    }

    return <>{getSwitch(isLoggedIn)}</>;
};
