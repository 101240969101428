import * as React from "react";
import { FormContext } from "../index";
import { useFormikContext } from "formik";
import { DatePicker, Form, FormItemProps, RangePickerProps } from "formik-antd";
import { DATE_FORMAT } from "../../../../../shared/functions";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import moment, { Moment } from "moment";

interface IProps {
    label: string;
    name: string;
    placeholder?: string;
    extraItemProps?: FormItemProps;
}

export const FormDateRangePickerItem: React.FC<IProps & RangePickerProps> = (
    props
) => {
    const formContext = React.useContext(FormContext);
    const f = useFormikContext();

    // @ts-ignore since the value is unknown.
    const value: [string | null, string | null] = f.getFieldMeta(props.name)
        .value;

    const preparedValue: [Moment | null, Moment | null] = [
        value[0] ? moment(value[0]) : null,
        value[1] ? moment(value[1]) : null,
    ];

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            {...props.extraItemProps}
        >
            <DatePicker.RangePicker
                disabled={formContext.readOnly}
                format={DATE_FORMAT}
                value={preparedValue}
                {...props}
                name={props.name}
                data-cy={`input-${toSnake(props.name).replace("_", "-")}`}
                onChange={(newValue) => {
                    if (newValue && newValue[0] && newValue[1]) {
                        const cleanedValues = [
                            newValue[0].toISOString(),
                            newValue[1].toISOString(),
                        ];
                        f.setFieldValue(props.name, cleanedValues);
                    } else {
                        f.setFieldValue(props.name, [null, null]);
                    }
                }}
                fast={formContext.fast}
            />
        </Form.Item>
    );
};
