export const orderTranslationEn = {
    title: "Orders",
    singular: "Order",
    create: "Create order",
    ordersWithoutInvoice: "Orders without invoice",
    totalPdf: "Total orders (PDF)",
    totalWeekOrder: "Total week order",
    fields: {
        id: "id",
        customer: "Customer",
        deliveryDate: "Delivery date",
        pickDate: "Pick date",
        celNumber: "Cel number",
        orderRows: "Rows",
        orderNr: "Order #",
        isInvoiced: "Is invoiced",
        invoices: "Invoices",
        orderAmount: "Orders (amount)",
        dates: "Dates",
        comment: "Comment",
        week: "Week",
    },
};
