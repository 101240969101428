export const navigationTranslationNl = {
    groups: "$t(groups.title)",
    logout: "Uitloggen",
    notFound: {
        title: "Niet gevonden",
        subTitle: "Deze pagina bestaat niet.",
        backHome: "Terug",
    },
    users: "$t(users.title)",
    customers: "$t(customers.title)",
    orders: "$t(orders.title)",
    invoices: "$t(invoices.title)",
    tenants: "$t(tenants.title)",
    admin: "Beheer",
    edit: "Aanpassen",
    create: "Aanmaken",
    detail: "Bekijken",
    overview: "Overzicht",
};
