import { BaseModel } from "../../normalized/models";
import { Type } from "class-transformer";
import { ProductRate } from "./ProductRate";

export class Product extends BaseModel {
    id: string;
    name: string;
    weight: number;
    price: number;
    vat: number;

    customer: string;

    @Type(() => ProductRate)
    productRates: ProductRate[];
}
