import { formatBackendValidationErrors } from "../../helpers/baseFormik";
import { Form } from "formik-antd";
import { Formik, FormikConfig, FormikHelpers, FormikProps } from "formik";
import * as React from "react";
import { NormalizeItemBase, SubState } from "../../../../shared/normalizer";
import { useStoreActions } from "../../../../store/hooks";
import * as Yup from "yup";
import { logger } from "../../../../shared/logging";
import { ID } from "../../../../shared/interfaces";
// tslint:disable-next-line:no-submodule-imports
import { FormProps } from "antd/lib/form/Form";
import { Col, Row } from "antd";
import { css, StyleSheet } from "aphrodite";
import { FormCleaners, FormContext } from "./index";
import { FormSubmitButton } from "./FormSubmitButton/FormSubmitButton";

type InitialDataObject = { id: ID | null };

interface IProps<T extends InitialDataObject> {
    /**
     * The SubState for which the form is created.
     */
    subState: SubState;
    /**
     * The initialValues for which the form is created.
     */
    initialValues: T;
    /**
     * The validationSchema for the form.
     */
    validationSchema: Yup.ObjectSchema<T>;
    /**
     * This function will be called after the form is submitted.
     * @param values: The new values.
     */
    onClose?: (values: T, helpers: FormikHelpers<T>) => void;
    /**
     * Extra props for the Formik component.
     */
    extraFormikProps?: Partial<FormikConfig<T>>;
    /**
     * Extra props for the Form component.
     */
    extraFormProps?: FormProps;
    /**
     * If the Submit button should NOT be rendered.
     */
    disableSubmitButton?: boolean;
    /**
     * Disable the whole form for readOnly state.
     */
    readonly?: boolean;
    /**
     * If the Fast fields should be enabled.
     */
    fast?: boolean;
    /**
     * Function will be triggered on render.
     */
    onRender?: (helpers: FormikProps<T>) => void;
    /**
     * extraActions.
     */
    extraActions?: (helpers: FormikProps<T>) => void;
    /**
     * If all values should be passed on update.
     */
    allValues?: boolean;
}

const styles = StyleSheet.create({
    submit: {
        width: "100%",
    },
});

export const BaseForm = <T extends InitialDataObject>(
    props: React.PropsWithChildren<IProps<T>>
) => {
    const { createItem, updateItem } = useStoreActions(
        (actions) => actions.normalize
    );

    const onSubmit = async (
        values: T,
        formikHelpers: FormikHelpers<T>
    ): Promise<boolean> => {
        const create: boolean = values.id === null;

        // Clean form if there is a cleaner available for the subState.
        const cleanedValues =
            props.subState in FormCleaners
                ? FormCleaners[props.subState](values)
                : values;

        logger.info(
            props.subState,
            create ? "create" : "update",
            cleanedValues
        );

        const onSuccess = (data: NormalizeItemBase) => {
            formikHelpers.setSubmitting(false);
            if (props.onClose) {
                props.onClose(
                    Object.values(data[props.subState])[0] as T,
                    formikHelpers
                );
            }

            return true;
        };

        const onError = (error: any) => {
            if (error.response && error.response.status === 400) {
                const validationErrors = formatBackendValidationErrors<T>(
                    error.response.data
                );
                formikHelpers.setErrors(validationErrors);
            }
            formikHelpers.setSubmitting(false);

            return false;
        };

        if (cleanedValues.id === null) {
            return createItem({
                subState: props.subState,
                data: cleanedValues,
            })
                .then(onSuccess)
                .catch(onError);
        } else {
            return updateItem({
                subState: props.subState,
                id: cleanedValues.id,
                data: cleanedValues,
                originalObject: props.allValues
                    ? undefined
                    : props.initialValues,
            })
                .then(onSuccess)
                .catch(onError);
        }
    };

    return (
        <Formik<T>
            onSubmit={onSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={props.initialValues}
            validationSchema={props.validationSchema}
            {...props.extraFormikProps}
        >
            {(helpers) => (
                <FormContext.Provider
                    value={{ readOnly: !!props.readonly, fast: props.fast }}
                >
                    <Form
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        {...props.extraFormProps}
                    >
                        {props.onRender && props.onRender(helpers)}
                        {props.children}

                        {!props.disableSubmitButton && !props.readonly && (
                            <Row>
                                <Col xs={0} md={14} xl={16} xxl={18} />
                                <Col xs={24} md={10} xl={8} xxl={6}>
                                    <FormSubmitButton
                                        className={css(styles.submit)}
                                        data-cy="button-submit"
                                    />
                                </Col>
                            </Row>
                        )}

                        {props.extraActions && props.extraActions(helpers)}
                    </Form>
                </FormContext.Provider>
            )}
        </Formik>
    );
};
