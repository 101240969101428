import * as React from "react";
import { BaseDetailScreen } from "../../base/components/BaseDetailScreen";
import { Order } from "../models";
import { OrderDetail } from "../components/OrderDetail/OrderDetail";

interface IProps {
    match: { params: { id: string } };
}

export const OrderDetailScreen: React.FC<IProps> = (props) => {
    return (
        <BaseDetailScreen<Order>
            match={props.match}
            subState={"orders"}
            detailComponent={(order) => <OrderDetail order={order} />}
        />
    );
};
