export const tenantsTranslationEn = {
    title: "Tenant",
    tableTitle: "Manage tenant",
    fields: {
        qsId: "QS ID",
        ogkNumber: "OGK number",
        ggnNumber: "GGN number",
        skalNumber: "SKAL number",
    },
};
