import {
    getSearchSelectFilter,
    IColumn,
    InputType,
} from "./SearchSelectFilter";
import moment, { Moment } from "moment";
import { AntColumnProps } from "../types";
import { t } from "../../../translation";
import React from "react";
import { YesNo } from "../../../modules/base/components/BaseForm/YesNo";

interface IOptions<T, K extends keyof T> {
    items?: IColumn[];
    customFilteredValue?: string[];
    enableSorter?: boolean;
    type?: InputType;
    render?: (value: T[K], allData: T) => any;
    enableFilter?: boolean;
    dataIndex?: string;
    width?: string;
}

type InitialFilters = { [key: string]: any } | null;

export class ColumnGenerator<T> {
    subState: string;
    initialFilters: InitialFilters;
    columns: AntColumnProps<T>[];

    translationPrefix: string;

    disableFilters: boolean;

    constructor(
        subState: string,
        initialFilters: InitialFilters,
        columns: AntColumnProps<T>[],
        translationPrefix?: string
    ) {
        this.subState = subState;
        this.initialFilters = initialFilters;
        this.columns = columns;
        this.disableFilters = initialFilters === null;
        this.translationPrefix = translationPrefix
            ? `${translationPrefix}.`
            : "";
    }

    generateColumn<K extends Extract<keyof T, string>>(
        keyInput: K,
        options?: IOptions<T, K>
    ): void {
        const key = keyInput as string;
        const dataIndex =
            options && options.dataIndex ? options.dataIndex : key;
        let sortOrder: "ascend" | "descend" | null = null;
        const initialValues =
            this.initialFilters && key in this.initialFilters
                ? this.initialFilters[key]
                : null;

        // Set initial order if it was set on the initialFilters
        if (
            this.initialFilters &&
            this.initialFilters.ordering !== undefined &&
            this.initialFilters.ordering.includes(key)
        ) {
            sortOrder = this.initialFilters.ordering.startsWith("-")
                ? "descend"
                : "ascend";
        }

        // Transform Moment if type is date and value is of type moment.ts
        if (options && options.type === "date" && !options.render) {
            // @ts-ignore
            options.render = (value: Moment) =>
                moment.isMoment(value) ? value.format("DD-MM-YYYY") : value;
        }

        // Transform bool if type is bool and value is of type bool
        if (options && options.type === "bool" && !options.render) {
            // @ts-ignore
            options.render = (value: boolean) => <YesNo yesNo={value} />;
        }

        let newColumn = {
            title: t(`${this.translationPrefix}${this.subState}.fields.${key}`),
            dataIndex,
            key,
            sorter: options && options.enableSorter && !this.disableFilters,
            sortOrder,
            render: options && options.render,
            filteredValue: initialValues,
            width: options && options.width,
        };

        if (options && options.enableFilter && !this.disableFilters) {
            newColumn = {
                ...newColumn,
                ...getSearchSelectFilter({
                    initialValues,
                    type: options && options.type,
                    items: options && options.items,
                }),
            };
        }
        this.columns.push(newColumn);
    }
}
