import * as React from "react";
import { SubState } from "../../../../shared/normalizer";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { getItemSelector } from "../../../normalized/selectors/getItemsSelector";
import { BaseLayout } from "../BaseLayout";
import { BasePermissionWrapper } from "../BasePermissionWrapper";
import { BaseNotFound } from "../BaseNotFound";
import { ID } from "../../../../shared/interfaces";

interface IProps<T> {
    match: { params: { id: string } };
    subState: SubState;
    detailComponent: (item: T) => React.ReactNode;
    actions?: React.ReactNode;
}

export const BaseDetailScreen = <T extends { id: ID }>(
    props: React.PropsWithChildren<IProps<T>>
) => {
    const id = props.match.params.id;

    const { getItem } = useStoreActions((actions) => actions.normalize);

    const item: T | null = useStoreState((state) =>
        id ? getItemSelector(state, props.subState, id) : null
    );

    const loading = useStoreState(
        (state) => state.normalize.meta[props.subState].getSingle.loading
    );

    React.useEffect(() => {
        if (id && !item) {
            getItem({ id, subState: props.subState });
        }
    }, []);

    return (
        <BaseLayout actions={props.actions}>
            <BasePermissionWrapper
                subState={props.subState}
                permission="view"
                showMessage={true}
                loading={loading}
            >
                {item ? props.detailComponent(item) : <BaseNotFound />}
            </BasePermissionWrapper>
        </BaseLayout>
    );
};
