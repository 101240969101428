export const buttonsTranslationNl = {
    create: "Aanmaken",
    update: "Opslaan",
    delete: "Verwijder",
    edit: "Aanpassen",
    reset: "Reset",
    request: "Aanvragen",
    view: "Bekijk",
    download: "Download",
    add: "Toevoegen",
    addProduct: "Product toevoegen",
    send: "Versturen",
};
