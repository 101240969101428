import * as React from "react";
import { BaseLayout } from "../../base/components/BaseLayout";
import { HijackUserTable } from "../components/HijackUserTable";
import { UserTable } from "../components/UserTable";
import { useCurrentUser } from "../../base/hooks";

export const UserOverviewScreen: React.FC = () => {
    const user = useCurrentUser();

    return (
        <BaseLayout>
            {user?.isSuperuser ? <HijackUserTable /> : <UserTable />}
        </BaseLayout>
    );
};
