import * as React from "react";
import { Button } from "antd";
import { HttpClient } from "../../../../shared/networking";
import { useHistory } from "react-router-dom";
import { getAuthenticatedPath } from "../../../../shared/routes";
import { Notifier } from "../../../../shared/functions";
import { t } from "../../../../translation";
import { showBackendValidationErrors } from "../../../base/helpers/baseFormik";

interface IProps {
    orderIds: string[];
    disabled?: boolean;
}

interface IResponse {
    invoice: string;
}

export const CreateInvoiceOrdersButton: React.FC<IProps> = (props) => {
    const history = useHistory();

    const createInvoice = () => {
        HttpClient.post<IResponse>(`/api/orders/invoice/`, {
            ids: props.orderIds,
        })
            .then((res) => {
                history.push(
                    getAuthenticatedPath("invoices-detail", { id: res.invoice })
                );
            })
            .catch((error) => {
                showBackendValidationErrors(error, t("http.crud.create.error"));
                Notifier.error("http.crud.create.error");
            });
    };

    return (
        <Button
            type="primary"
            onClick={() => createInvoice()}
            disabled={props.disabled || props.orderIds.length === 0}
        >
            {t("invoices.create")} [{props.orderIds.length}]
        </Button>
    );
};
