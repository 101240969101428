export const invoicesTranslationEn = {
    title: "Invoices",
    singular: "Invoice",
    create: "Create invoice",
    preview: "Preview",
    alreadySent:
        "This invoice has already been send and can not be edited anymore.",
    sendSuccess: "The invoice has been sent successfully!",
    sendError: "Something went wrong while sending the invoice.",
    fields: {
        invoiceNumber: "#",
        customer: "Customer",
        date: "Date",
        paymentDue: "Payment due",
        reference: "Reference",
        period: "Period",
        totalExcl: "Total (Excl)",
        totalIncl: "Total (Incl)",
        isSent: "Sent",
    },
    invoiceRows: {
        title: "Invoice rows",
        singular: "Invoice row",
        fields: {
            description: "Description",
            date: "Date",
            quantity: "Quantity",
            unitPrice: "Unit price",
            vat: "Vat %",
        },
    },
};
