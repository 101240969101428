export const customersTranslationEn = {
    title: "Customers",
    singular: "Customer",
    fields: {
        id: "id",
        name: "Name",
        address: "Address",
        country: "Country",
        phone: "Phone",
        invoiceEmail: "Invoice email",
        invoiceSubTotals: "Invoice sub totals",
        bioNumber: "Bio number",
        vatNumber: "VAT nummer",
        cocNumber: "COC nummer",
        contactPersonName: "Name contact person",
        contactPersonEmail: "Email contact person",
        isActive: "Active",
        hideOnDashboard: "Hide on dashboard",
        noteOnPdf: "Note on PDF",
    },
};
