import * as React from "react";
import { Form, InputNumber, InputNumberProps } from "formik-antd";
// tslint:disable-next-line:no-submodule-imports
import { FormItemProps } from "formik-antd/src/form-item/index";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import { FormContext } from "../index";

interface IProps {
    label: string;
    name: string;
    placeholder?: string;
    extraItemProps?: Omit<FormItemProps, "children" | "name">;
}

export const FormInputNumberItem: React.FC<IProps & InputNumberProps> = (
    props
) => {
    const formContext = React.useContext(FormContext);

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            {...props.extraItemProps}
        >
            <InputNumber
                disabled={formContext.readOnly}
                fast={formContext.fast}
                {...props}
                name={props.name}
                placeholder={(props.placeholder || props.label).toLowerCase()}
                data-cy={`input-${toSnake(props.name).replace("_", "-")}`}
            />
        </Form.Item>
    );
};
