import * as Yup from "yup";
import { getTypedSchema } from "../../base/helpers/baseFormik";
import { Invoice } from "../models";
import moment from "moment";
import { API_DATE_FORMAT } from "../../../shared/functions";

export interface IInvoiceRowForm {
    id: string | null;
    description: string;
    date: string | null;
    quantity: number;
    unitPrice: number;
    vat: number;
}

export interface IInvoiceForm {
    id: string | null;
    customer: string | null;

    date: string;
    paymentDue: string;

    reference: string;
    period: string;

    invoiceRows: IInvoiceRowForm[];
}

export const getInvoiceRowInitialValues = (): IInvoiceRowForm => ({
    id: null,
    description: "",
    date: null,
    quantity: 1,
    unitPrice: 0,
    vat: 0,
});

export const getInvoiceInitialValues = (): IInvoiceForm => ({
    id: null,
    customer: null,
    date: moment().toISOString(),
    paymentDue: moment()
        .add(30, "days")
        .toISOString(),
    period: "",
    reference: "",
    invoiceRows: [getInvoiceRowInitialValues()],
});

export const invoiceToValues = (invoice: Invoice): IInvoiceForm => ({
    id: invoice.id,
    customer: invoice.customer.id,
    date: moment(invoice.date, API_DATE_FORMAT).toISOString(),
    paymentDue: moment(invoice.paymentDue, API_DATE_FORMAT).toISOString(),
    reference: invoice.reference,
    period: invoice.period,
    invoiceRows: invoice.invoiceRows.map((invoiceRow) => ({
        id: invoiceRow.id,
        description: invoiceRow.description,
        date: invoiceRow.date
            ? moment(invoiceRow.date, API_DATE_FORMAT).toISOString()
            : null,
        quantity: invoiceRow.quantity,
        unitPrice: invoiceRow.unitPrice,
        vat: invoiceRow.vat,
    })),
});

export const getInvoiceValidationSchema = () =>
    getTypedSchema<IInvoiceForm>({
        id: Yup.string().nullable(),
        customer: Yup.string()
            .nullable()
            .required(),
        date: Yup.string(),
        paymentDue: Yup.string(),
        reference: Yup.string(),
        period: Yup.string(),
        invoiceRows: Yup.array().of(
            Yup.object().shape({
                id: Yup.string().nullable(),
                description: Yup.string().min(2),
                date: Yup.string().nullable(),
                quantity: Yup.number().min(0),
                unitPrice: Yup.number(),
                vat: Yup.number(),
            })
        ),
    });
