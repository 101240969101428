import * as React from "react";
import { Tenant } from "../../models";
import { BaseForm, FormInputItem } from "../../../base/components/BaseForm";
import { t } from "../../../../translation";
import { Typography } from "antd";
import {
    getTenantValidationSchema,
    ITenantForm,
    tenantToValues,
} from "../../forms/tenantInitialValues";

interface IProps {
    tenant: Tenant;
}

export const EditTenantForm: React.FC<IProps> = (props) => {
    return (
        <BaseForm<ITenantForm>
            subState="tenants"
            initialValues={tenantToValues(props.tenant)}
            validationSchema={getTenantValidationSchema()}
        >
            <Typography.Title level={3}>{props.tenant.name}</Typography.Title>
            <FormInputItem name="qsId" label={t("tenants.fields.qsId")} />
            <FormInputItem
                name="ogkNumber"
                label={t("tenants.fields.ogkNumber")}
            />
            <FormInputItem
                name="ggnNumber"
                label={t("tenants.fields.ggnNumber")}
            />
            <FormInputItem
                name="skalNumber"
                label={t("tenants.fields.skalNumber")}
            />
        </BaseForm>
    );
};
