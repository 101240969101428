import { Country } from "./models/Country";
import { getCountriesThunk } from "./thunks/getCountriesThunk";
import { action, Action } from "easy-peasy";

export interface ICustomerState {
    /**
     * State
     */
    getCountriesLoading: boolean;
    getCountriesDone: boolean;
    getCountriesError: Error | null;
    countries: Country[];

    /**
     * Actions
     */
    getCountriesBegin: Action<ICustomerState>;
    getCountriesSuccess: Action<ICustomerState, Country[]>;
    getCountriesFailure: Action<ICustomerState, Error>;

    /**
     * Thunks
     */
    getCountries: typeof getCountriesThunk;
}

export const customerState: ICustomerState = {
    /**
     * State
     */
    getCountriesLoading: false,
    getCountriesDone: false,
    getCountriesError: null,
    countries: [],

    /**
     * Thunks
     */
    getCountries: getCountriesThunk,

    /**
     * Actions
     */
    getCountriesBegin: action((state) => {
        state.getCountriesLoading = true;
        state.getCountriesDone = false;
        state.getCountriesError = null;
    }),
    getCountriesSuccess: action((state, payload) => {
        state.getCountriesLoading = false;
        state.getCountriesDone = true;
        state.getCountriesError = null;
        state.countries = payload;
    }),
    getCountriesFailure: action((state, payload) => {
        state.getCountriesLoading = false;
        state.getCountriesDone = false;
        state.getCountriesError = payload;
    }),
};
