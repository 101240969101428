import * as React from "react";
import { BaseLayout } from "../../base/components/BaseLayout";
import { t } from "../../../translation";
import { BaseTable } from "../../base/components";
import { Order } from "../models";
import { getAuthenticatedPath } from "../../../shared/routes";
import { useHistory } from "react-router-dom";
import { DownloadTotalPdfButton } from "../components/DownloadTotalPdfButton";
import { CreateInvoiceOrdersButton } from "../../invoices/components/CreateInvoiceOrdersButton";
import { useStoreState } from "../../../store/hooks";

export const OrderOverviewScreen: React.FC = () => {
    const history = useHistory();
    const selectedOrderIds = useStoreState(
        (state) => state.normalize.meta.orders.selectedIds
    ) as string[];

    return (
        <BaseLayout>
            <BaseTable<Order>
                subState="orders"
                title={t("orders.title")}
                enableWebSockets={true}
                forceFetchOnMount={true}
                onEdit={(order) =>
                    history.push(
                        getAuthenticatedPath("orders-detail", {
                            id: order.id,
                        })
                    )
                }
                showActionBar={true}
                globalFilters={true}
            >
                <DownloadTotalPdfButton />
                <CreateInvoiceOrdersButton orderIds={selectedOrderIds} />
            </BaseTable>
        </BaseLayout>
    );
};
