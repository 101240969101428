import * as React from "react";
import { Form, Switch, SwitchProps } from "formik-antd";
// tslint:disable-next-line:no-submodule-imports
import { FormItemProps } from "formik-antd/src/form-item/index";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import { FormContext } from "../index";

interface IProps {
    label: string;
    name: string;
    extraItemProps?: FormItemProps;
}

export const FormSwitchItem: React.FC<IProps & SwitchProps> = (props) => {
    const formContext = React.useContext(FormContext);

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            {...props.extraItemProps}
        >
            <Switch
                disabled={formContext.readOnly}
                {...props}
                name={props.name}
                data-cy={`input-${toSnake(props.name).replace("_", "-")}`}
                fast={props.fast}
            />
        </Form.Item>
    );
};
