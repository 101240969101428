import * as React from "react";
import { BaseTable } from "../../base/components/BaseTable";
import { BaseLayout } from "../../base/components/BaseLayout";
import { t } from "../../../translation";

export const GroupOverviewScreen: React.FC = () => (
    <BaseLayout>
        <BaseTable subState="groups" title={t("groups.title")} />
    </BaseLayout>
);
