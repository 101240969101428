import * as React from "react";
import { Customer } from "../../models";
import { BaseTab } from "../../../base/components/BaseTab";
import {
    BarcodeOutlined,
    BarsOutlined,
    EuroOutlined,
    TableOutlined,
} from "@ant-design/icons";
import { t } from "../../../../translation";
import { EditCustomerForm } from "../EditCustomerForm";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { Typography } from "antd";
import { ProductTab } from "./tabs/ProductTab";
import { BaseTable } from "../../../base/components";
import { Invoice } from "../../../invoices/models";
import { Order } from "../../../orders/models";
import { getAuthenticatedPath } from "../../../../shared/routes";
import { useHistory } from "react-router-dom";
import { CreateInvoiceOrdersButton } from "../../../invoices/components/CreateInvoiceOrdersButton";

interface IProps {
    customer: Customer;
}

export const CustomerDetail: React.FC<IProps> = (props) => {
    const history = useHistory();
    const { getItem } = useStoreActions((actions) => actions.normalize);

    const selectedOrderIds = useStoreState(
        (state) => state.normalize.meta.orders.selectedIds
    ) as string[];

    const reload = () => {
        getItem({ subState: "customers", id: props.customer.id });
    };

    return (
        <>
            <Typography.Title level={3}>
                {t("customers.singular")}:{" "}
                {props.customer.name || t("forms.unknown")}
            </Typography.Title>
            <BaseTab
                tabs={[
                    {
                        title: (
                            <>
                                <TableOutlined />
                                {t("navigation.overview")}
                            </>
                        ),
                        content: (
                            <EditCustomerForm
                                customer={props.customer}
                                onClose={() => reload()}
                            />
                        ),
                        key: "overview",
                    },
                    {
                        title: (
                            <>
                                <BarcodeOutlined />
                                {t("products.title")}
                            </>
                        ),
                        content: (
                            <ProductTab
                                customer={props.customer}
                                reload={() => reload()}
                            />
                        ),
                        key: "products",
                    },
                    {
                        title: (
                            <>
                                <BarsOutlined />
                                {t("orders.title")}
                            </>
                        ),
                        content: (
                            <>
                                <BaseTable<Order>
                                    subState="orders"
                                    disableFilters={true}
                                    height={"calc(100vh - 385px - 7em)"}
                                    forceFetchOnMount={true}
                                    extraFilters={{
                                        customerId: props.customer.id,
                                    }}
                                    onEdit={(order) =>
                                        history.push(
                                            getAuthenticatedPath(
                                                "orders-detail",
                                                {
                                                    id: order.id,
                                                }
                                            )
                                        )
                                    }
                                />
                                <div
                                    style={{
                                        marginTop: "1em",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <CreateInvoiceOrdersButton
                                        disabled={selectedOrderIds.length === 0}
                                        orderIds={selectedOrderIds}
                                    />
                                </div>
                            </>
                        ),
                        key: "orders",
                    },
                    {
                        title: (
                            <>
                                <EuroOutlined />
                                {t("invoices.title")}
                            </>
                        ),
                        content: (
                            <>
                                <BaseTable<Invoice>
                                    subState="invoices"
                                    extraFilters={{
                                        customerId: props.customer.id,
                                    }}
                                    onEdit={(invoice) =>
                                        history.push(
                                            getAuthenticatedPath(
                                                "invoices-detail",
                                                { id: invoice.id }
                                            )
                                        )
                                    }
                                />
                            </>
                        ),
                        key: "invoices",
                    },
                ]}
            />
        </>
    );
};
