import * as React from "react";
import moment from "moment";
import { t } from "../../../../translation";
import * as qs from "qs";
import { API_DATE_FORMAT } from "../../../../shared/functions";
import { Formik } from "formik";
import { Form } from "formik-antd";
import {
    FormSelectItem,
    FormTextAreaItem,
} from "../../../base/components/BaseForm";
import { FormSubmitButton } from "../../../base/components/BaseForm/FormSubmitButton/FormSubmitButton";
import { useNormalizedState } from "../../../base/hooks";
import { Customer } from "../../../customers/models";
import { FormDateRangePickerItem } from "../../../base/components/BaseForm/FormDateRangePickerItem";
import { keysToSnake } from "../../../../shared/functions/caseCovertion";

interface IForm {
    dates: [string, string];
    customerId: string | null;
    comment: string;
}

export const DownloadTotalWeekPdf = () => {
    const customers = useNormalizedState<Customer>("customers").data.filter(
        (customer) => customer.isActive
    );

    const onDownload = (values: IForm) => {
        const params = qs.stringify(
            keysToSnake({
                start: moment(values.dates[0]).format(API_DATE_FORMAT),
                end: moment(values.dates[1]).format(API_DATE_FORMAT),
                customerId: values.customerId || undefined,
                comment: values.comment,
            }),
            {
                addQueryPrefix: true,
            }
        );

        window.open(`/api/orders/multiple_pdf/${params}`);
    };

    return (
        <Formik<IForm>
            initialValues={{
                dates: [
                    moment()
                        .startOf("isoWeek")
                        .toISOString(),
                    moment()
                        .endOf("isoWeek")
                        .toISOString(),
                ],
                customerId: null,
                comment: "",
            }}
            onSubmit={(values, formikHelpers) => {
                onDownload(values);
                formikHelpers.setSubmitting(false);
            }}
        >
            <Form
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <FormDateRangePickerItem
                    label={t("orders.fields.dates")}
                    name="dates"
                    allowClear={false}
                    picker="date"
                />

                <FormSelectItem
                    label={t("orders.fields.customer")}
                    name="customerId"
                    options={customers.map((customer) => ({
                        label: customer.name,
                        value: customer.id,
                    }))}
                    withFilter={true}
                    allowClear={true}
                />

                <FormTextAreaItem
                    label={t("orders.fields.comment")}
                    name="comment"
                />

                <FormSubmitButton>{t("orders.totalPdf")}</FormSubmitButton>
            </Form>
        </Formik>
    );
};
