import * as Yup from "yup";
import { getTypedSchema } from "../../base/helpers/baseFormik";
import { Customer } from "../models";

export interface ICustomerForm {
    id: string | null;
    name: string;
    address: string;
    country: string;
    phone: string;
    invoiceEmail: string;
    invoiceSubTotals: boolean;
    bioNumber: string;
    vatNumber: string;
    cocNumber: string;
    contactPersonName: string;
    contactPersonEmail: string;
    isActive: boolean;
    hideOnDashboard: boolean;
    noteOnPdf: string;
}

export const getCustomerInitialValues = (): ICustomerForm => ({
    id: null,
    name: "",
    address: "",
    country: "NL",
    phone: "",
    invoiceEmail: "",
    invoiceSubTotals: false,
    bioNumber: "",
    vatNumber: "",
    cocNumber: "",
    contactPersonName: "",
    contactPersonEmail: "",
    isActive: true,
    hideOnDashboard: false,
    noteOnPdf: "",
});

export const customerToValues = (customer: Customer): ICustomerForm => ({
    id: customer.id,
    name: customer.name,
    address: customer.address,
    country: customer.country,
    phone: customer.phone,
    invoiceEmail: customer.invoiceEmail,
    invoiceSubTotals: customer.invoiceSubTotals,
    bioNumber: customer.bioNumber,
    vatNumber: customer.vatNumber,
    cocNumber: customer.cocNumber,
    contactPersonName: customer.contactPersonName,
    contactPersonEmail: customer.contactPersonEmail,
    isActive: customer.isActive,
    hideOnDashboard: customer.hideOnDashboard,
    noteOnPdf: customer.noteOnPdf,
});

export const getCustomerValidationSchema = () =>
    getTypedSchema<ICustomerForm>({
        id: Yup.string().nullable(),
        name: Yup.string()
            .min(2)
            .max(50)
            .required(),
        address: Yup.string().required(),
        country: Yup.string().required(),
        phone: Yup.string(),
        invoiceEmail: Yup.string().required(),
        invoiceSubTotals: Yup.boolean(),
        bioNumber: Yup.string(),
        vatNumber: Yup.string(),
        cocNumber: Yup.string(),
        contactPersonName: Yup.string(),
        contactPersonEmail: Yup.string(),
        isActive: Yup.boolean(),
        hideOnDashboard: Yup.boolean(),
        noteOnPdf: Yup.string(),
    });
