import { thunk, Thunk } from "easy-peasy";
import { RootState } from "../../../store/storeModel";
import { HttpClient } from "../../../shared/networking";
import { logger } from "../../../shared/logging";
import { Country } from "../models/Country";
import { ICustomerState } from "../state";

export const getCountriesThunk: Thunk<
    ICustomerState,
    any,
    any,
    RootState,
    Promise<Country[]>
> = thunk((actions) => {
    actions.getCountriesBegin();

    return HttpClient.get<Country[]>(`/api/customers/countries/`)
        .then((data) => {
            actions.getCountriesSuccess(data);
            return data;
        })
        .catch((error) => {
            logger.error(error);
            actions.getCountriesFailure(error);
            throw error;
        });
});
