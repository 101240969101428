export const invoicesTranslationNl = {
    title: "Facturen",
    singular: "Factuur",
    create: "Factuur maken",
    preview: "Voorbeeld",
    alreadySent:
        "Deze factuur is al verstuurd en kan niet meer aangepast worden.",
    sendSuccess: "De factuur is successvol verstuurd!",
    sendError: "Er is iets fout gegaan bij het versturen van de factuur",
    fields: {
        invoiceNumber: "#",
        customer: "Klant",
        date: "Datum",
        paymentDue: "Uiterlijke betaaldatum",
        reference: "Referentie",
        period: "Periode",
        totalExcl: "Totaal (Excl)",
        totalIncl: "Totaal (Incl)",
        isSent: "Verstuurd",
    },
    invoiceRows: {
        title: "Factuur rijen",
        singular: "Factuur rij",
        fields: {
            description: "Beschrijving",
            date: "Datum",
            quantity: "Aantal",
            unitPrice: "Prijs per stuk",
            vat: "BTW %",
        },
    },
};
