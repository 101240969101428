import { buttonsTranslationEn } from "./buttons";
import { usersTranslationEn } from "./users";
import { filtersTranslationEn } from "./filters";
import { navigationTranslationEn } from "./navigation";
import { groupsTranslationEn } from "./groups";
import { formsTranslationEn } from "./forms";
import { httpTranslationEn } from "./http";
import { homeTranslationEn } from "./home";
import { orderTranslationEn } from "./orders";
import { productsTranslationEn } from "./products";
import { productRatesTranslationEn } from "./productRates";
import { customersTranslationEn } from "./customers";
import { invoicesTranslationEn } from "./invoices";
import { tenantsTranslationEn } from "./tenants";

export const TranslationEn = {
    buttons: buttonsTranslationEn,
    users: usersTranslationEn,
    filters: filtersTranslationEn,
    navigation: navigationTranslationEn,
    groups: groupsTranslationEn,
    forms: formsTranslationEn,
    http: httpTranslationEn,
    home: homeTranslationEn,
    customers: customersTranslationEn,
    orders: orderTranslationEn,
    products: productsTranslationEn,
    productRates: productRatesTranslationEn,
    invoices: invoicesTranslationEn,
    tenants: tenantsTranslationEn,
};
