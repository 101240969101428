import * as React from "react";
import { useHistory } from "react-router-dom";
import { BaseLayout } from "../../base/components/BaseLayout";
import { BaseTable } from "../../base/components";
import { t } from "../../../translation";
import { getAuthenticatedPath } from "../../../shared/routes";
import { Invoice } from "../models";

export const InvoiceOverviewScreen: React.FC = () => {
    const history = useHistory();

    return (
        <BaseLayout>
            <BaseTable<Invoice>
                subState="invoices"
                title={t("invoices.title")}
                enableWebSockets={true}
                forceFetchOnMount={true}
                onEdit={(invoice) =>
                    history.push(
                        getAuthenticatedPath("invoices-detail", {
                            id: invoice.id,
                        })
                    )
                }
                showActionBar={true}
            />
        </BaseLayout>
    );
};
