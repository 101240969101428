import * as React from "react";

export const useFocusEffect = (func: () => void) => {
    const onFocus = () => {
        func();
    };

    React.useEffect(() => {
        window.addEventListener("focus", onFocus);

        onFocus();

        return () => {
            window.removeEventListener("focus", onFocus);
        };
    }, []);
};
