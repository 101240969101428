import * as React from "react";
import { BaseTable } from "../../../base/components/BaseTable";
import { LoginOutlined } from "@ant-design/icons/lib";
import { Button } from "antd";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { Group, User } from "../../../auth/models";
import { getItemsSelector } from "../../../normalized/selectors/getItemsSelector";
import { t } from "../../../../translation";
import { EditUserModal } from "../../screens/EditUserModal";

export const HijackUserTable: React.FC = () => {
    const hijackLoginAction = useStoreActions(
        (actions) => actions.auth.hijackLogin
    );
    const groups: Group[] = useStoreState((state) =>
        getItemsSelector(state, "groups")
    );

    const [editUserModal, setEditUserModal] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

    return (
        <>
            <EditUserModal
                user={selectedUser}
                visible={editUserModal}
                onClose={() => setEditUserModal(false)}
            />
            <BaseTable<User>
                subState="users"
                title={t("users.tableTitle")}
                filtersInUrl={true}
                extraColumnData={{ groups }}
                onCreate={() => {
                    setSelectedUser(null);
                    setEditUserModal(true);
                }}
                onEdit={(user) => {
                    setSelectedUser(user);
                    setEditUserModal(true);
                }}
                extraColumns={[
                    {
                        align: "right",
                        width: "50px",
                        render: (record: any) => (
                            <Button
                                type="default"
                                shape="circle"
                                onClick={() => {
                                    hijackLoginAction({
                                        userId: record.key,
                                    }).then(() => window.location.reload());
                                }}
                                icon={<LoginOutlined />}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};
