import * as React from "react";
import { Form, Input, InputProps } from "formik-antd";
// tslint:disable-next-line:no-submodule-imports
import { FormItemProps } from "formik-antd/src/form-item/index";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import { FormContext } from "../index";

interface IProps {
    label: string;
    name: string;
    placeholder?: string;
    extraItemProps?: Partial<FormItemProps>;
}

export const FormInputItem: React.FC<IProps & InputProps> = (props) => {
    const formContext = React.useContext(FormContext);

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            {...props.extraItemProps}
        >
            <Input
                disabled={formContext.readOnly}
                {...props}
                name={props.name}
                placeholder={(props.placeholder || props.label).toLowerCase()}
                data-cy={`input-${toSnake(props.name)
                    .replace("_", "-")
                    .replace(".", "-")}`}
                fast={formContext.fast}
            />
        </Form.Item>
    );
};
