import * as Yup from "yup";
import { getTypedSchema } from "../../base/helpers/baseFormik";
import { Product } from "../models";
import moment from "moment";

export interface IProductForm {
    id: string | null;
    name: string;
    weight: number;
    price: number;
    vat: number;
    date: string;
    customer: string;
}

export const getProductInitialValues = (customerId: string): IProductForm => ({
    id: null,
    name: "",
    weight: 1.0,
    price: 0.0,
    vat: 9,
    date: moment().toISOString(),
    customer: customerId,
});

export const productToValues = (product: Product): IProductForm => ({
    id: product.id,
    name: product.name,
    weight: product.weight,
    price: product.price,
    vat: product.vat,
    date: moment().toISOString(),
    customer: product.customer,
});

export const getProductValidationSchema = () =>
    getTypedSchema<IProductForm>({
        id: Yup.string().nullable(),
        name: Yup.string()
            .min(2)
            .max(50)
            .required(),
        price: Yup.number().required(),
        vat: Yup.number().required(),
        weight: Yup.number().required(),
        date: Yup.string(),
        customer: Yup.string().required(),
    });
