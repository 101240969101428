import * as React from "react";
import { useFormikContext } from "formik";
import { Button } from "antd";
import { t } from "../../../../../translation";
import { FormContext } from "../index";

interface IProps {
    disabled?: boolean;
    className?: string;
    ["data-cy"]?: string;
}

export const FormSubmitButton: React.FC<IProps> = (props) => {
    const formik = useFormikContext();
    const formContext = React.useContext(FormContext);

    let content = props.children;

    if (!content) {
        // @ts-ignore
        content = !!formik.values.id ? t("buttons.edit") : t("buttons.create");
    }

    return (
        <Button
            loading={formik.isSubmitting}
            type="primary"
            htmlType="submit"
            {...props}
            disabled={props.disabled || formContext.readOnly}
        >
            {content}
        </Button>
    );
};
