import * as React from "react";
import { Form } from "formik-antd";
import { t } from "../../../../translation";
import {
    FormInputNumberItem,
    FormSelectItem,
} from "../../../base/components/BaseForm";
import { Button } from "antd";
import { FieldArray, useFormikContext } from "formik";
import { IOrderForm, IOrderRowForm } from "../../forms/orderInitialValues";
import { useNormalizedState } from "../../../base/hooks";
import { Product } from "../../../customers/models";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const EditOrderRowForm: React.FC = () => {
    const f = useFormikContext<IOrderForm>();

    const { data: products } = useNormalizedState<Product>("products");

    return (
        <FieldArray
            name="orderRows"
            render={(helpers) => (
                <>
                    <Form.Item name=" " label={t("orders.fields.orderRows")}>
                        <>
                            {f.values.orderRows.map((_, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                    }}
                                >
                                    <FormInputNumberItem
                                        key={index}
                                        label=""
                                        name={`orderRows[${index}].quantity`}
                                    />

                                    <FormSelectItem
                                        label=""
                                        placeholder={t("products.singular")}
                                        name={`orderRows[${index}].product`}
                                        options={products
                                            .filter(
                                                (product) =>
                                                    product.customer ===
                                                    f.values.customer
                                            )
                                            .map((product) => ({
                                                value: product.id,
                                                label: product.name,
                                            }))}
                                        style={{ maxWidth: "10em" }}
                                        withFilter={true}
                                        extraItemProps={{
                                            style: {
                                                marginLeft: "1em",
                                                marginRight: "1em",
                                                width: "100%",
                                            },
                                        }}
                                    />

                                    <Button
                                        icon={<DeleteOutlined />}
                                        type="danger"
                                        style={{ width: "4em" }}
                                        onClick={() => helpers.remove(index)}
                                    />
                                </div>
                            ))}

                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                ghost={true}
                                onClick={() => {
                                    const item: IOrderRowForm = {
                                        id: null,
                                        quantity: 1,
                                        product: null,
                                    };
                                    helpers.push(item);
                                }}
                            >
                                {t("buttons.addProduct")}
                            </Button>
                        </>
                    </Form.Item>
                </>
            )}
        />
    );
};

export { EditOrderRowForm };
