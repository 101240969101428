// tslint:disable:no-submodule-imports
import * as React from "react";
import { StoreProvider } from "easy-peasy";
import { store } from "./store";
import { AuthGuard } from "./modules/auth/components";
import { BaseRouter, WsController } from "./modules/base/components";
import { BrowserRouter as Router } from "react-router-dom";
import { BaseConfigProvider } from "./modules/base/components/BaseConfigProvider";

import "./styles/antd.less";
import "moment/locale/nl";

export const App = () => {
    return (
        <StoreProvider store={store}>
            <BaseConfigProvider>
                <Router>
                    <AuthGuard />
                    <WsController />
                    <BaseRouter />
                </Router>
            </BaseConfigProvider>
        </StoreProvider>
    );
};
