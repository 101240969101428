import * as React from "react";
import { Form, Select } from "formik-antd";
import { Group, User } from "../../../auth/models";
import {
    getUserInitialValues,
    getUserValidationSchema,
    IUserForm,
    userToValues,
} from "../../forms/userInitialValues";
import { t } from "../../../../translation";
import { BaseForm, FormInputItem } from "../../../base/components/BaseForm";
import { useNormalizedState } from "../../../base/hooks";

interface IProps {
    user: User | null;
    onClose?: () => void;
}

export const EditUserForm: React.FC<IProps> = (props) => {
    const { data: groups } = useNormalizedState<Group>("groups");

    return (
        <BaseForm<IUserForm>
            subState="users"
            initialValues={
                props.user ? userToValues(props.user) : getUserInitialValues()
            }
            validationSchema={getUserValidationSchema()}
            onClose={() => (props.onClose ? props.onClose() : null)}
        >
            <FormInputItem name="username" label={t("users.fields.username")} />
            <FormInputItem name="email" label={t("users.fields.email")} />
            <FormInputItem
                name="firstName"
                label={t("users.fields.firstName")}
            />
            <FormInputItem name="lastName" label={t("users.fields.lastName")} />
            <Form.Item name="groups" label={t("users.fields.groups")}>
                <Select
                    mode="multiple"
                    name="groups"
                    placeholder={t("users.fields.groups").toLowerCase()}
                    data-cy="input-groups"
                >
                    {groups.map((group) => (
                        <Select.Option key={group.id} value={group.id}>
                            {group.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </BaseForm>
    );
};
