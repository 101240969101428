import * as React from "react";
import { Invoice } from "../../models";
import { Button, Modal, Typography } from "antd";
import { t } from "../../../../translation";
import { HttpClient } from "../../../../shared/networking";
import { Notifier } from "../../../../shared/functions";
import { useStoreActions } from "../../../../store/hooks";

interface IProps {
    invoice: Invoice;
}

export const SendInvoiceButton: React.FC<IProps> = (props) => {
    const [modal, setModal] = React.useState(false);
    const [preview, setPreview] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const { getItem } = useStoreActions((actions) => actions.normalize);

    const sendInvoice = () => {
        setLoading(true);
        HttpClient.post(`/api/invoices/${props.invoice.id}/email/`)
            .then(() => {
                Notifier.success(t("invoices.sendSuccess"));
                setLoading(false);
                getItem({ subState: "invoices", id: props.invoice.id });
            })
            .catch(() => {
                Notifier.error(t("invoices.sendError"));
                setLoading(false);
            });
        setModal(false);
    };

    const getPreview = async () => {
        const result = await HttpClient.post<string>(
            `/api/invoices/${props.invoice.id}/email/?preview=true`
        );
        setPreview(result);
    };

    return (
        <>
            {modal && (
                <Modal
                    visible={true}
                    onCancel={() => setModal(false)}
                    onOk={() => sendInvoice()}
                    okText={t("buttons.send")}
                    width="90%"
                >
                    <Typography.Title level={4}>
                        {t("invoices.preview")}
                    </Typography.Title>
                    <iframe
                        title="email-preview"
                        style={{
                            width: "100%",
                            height: "500px",
                        }}
                        srcDoc={preview}
                    />
                </Modal>
            )}

            <Button
                type="primary"
                onClick={() => {
                    getPreview();
                    setModal(true);
                }}
                disabled={!!props.invoice.sentAt}
                loading={loading}
            >
                {t("buttons.send")}
            </Button>
        </>
    );
};
