import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { INormalizeState } from "../state";
import { logger } from "../../../shared/logging";
import {
    getDefinitionForSubState,
    NormalizeItemBase,
    SubState,
} from "../../../shared/normalizer";
import { Notifier } from "../../../shared/functions";
import { ID } from "../../../shared/interfaces";

interface IPayload {
    subState: SubState;
    id: ID;
}

/**
 * Generic function to get an item from the normalized state
 */
export const getItemThunk: Thunk<
    INormalizeState,
    IPayload,
    any,
    RootState,
    Promise<NormalizeItemBase>
> = thunk(async (actions, payload) => {
    actions.getItemBegin({ subState: payload.subState });
    const definition = getDefinitionForSubState(payload.subState);

    if (definition.requiredSubStates) {
        for (const requiredSubState of definition.requiredSubStates) {
            await actions.getItems({
                subState: requiredSubState,
            });
        }
    }

    return HttpClient.get<NormalizeItemBase>(
        `/api/${definition.baseUrl}/${payload.id}/`,
        {
            schema: definition.schema,
        }
    )
        .then((data) => {
            actions.getItemSuccess({
                data,
                subState: payload.subState,
            });
            return data;
        })
        .catch((error) => {
            logger.error(error);
            Notifier.error("http.crud.read.error");
            actions.getItemFailure({ error, subState: payload.subState });
            throw error;
        });
});
